import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCourseFillDetails, getCourses } from "../../API/courses";
import FormControl from "@mui/material/FormControl";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { createStudent, updateKid } from "../../API/kids-mgmt";
import "./addNewKids.css";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import { Checkbox, ListItemText } from "@mui/material";

interface handelFuncProps {
  handelClose: () => void;
  data?: {
    unique_id: Number;
    kid_name: string[];
    age: number;
    gender: string;
    parents_name: string;
    contact: string;
    interests: string[];
    courses: string[];
    school: string;
    city: string;
    name: string;
    __v?: Number;
    _id: string;
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddNewKids(props: handelFuncProps) {
  const theme = useTheme();
  const [name, setName] = useState<string>(props.data?.name || "");
  const [Age, setAge] = useState(props.data?.age || "");
  const [mobile, setMobile] = useState(props.data?.contact || "");
  const [city, setCity] = useState<string>(props.data?.city || "");
  const [parents, setParents] = useState(props.data?.parents_name || "");
  const [choosen, setChoosen] = useState(false);
  const [choosenCourse, setChoosenCourse] = useState(false);
  const [category, setCategory] = useState<string[]>(
    props.data?.interests || []
  );
  const [Gender, setGender] = useState<string>(props.data?.gender || "male");
  const [schoolName, setSchoolName] = useState(props.data?.school || "");
  const [show, setShow] = useState<boolean>(false);
  const [List, setList] = useState<any>([]);
  const [catList, setCatList] = useState<any>([]);
  const [courseRow, setCourseRow] = useState<any>([]);
  const [course, setCourse] = useState<string[]>(props.data?.courses || []);
  const [myList, setMyList] = useState<any>([]);

  const handleClick = () => {
    return props.handelClose();
  };

  useEffect(() => {
    getFirstdata();
    if (props.data) {
      setCourse(props.data?.courses.map((m: any) => m?._id));
      setCategory(props.data?.interests.map((m: any) => m?._id));
    }
  }, []);

  // useEffect(() => {
  //   let arr = List;
  //   let x = arr
  //     .filter((m, i) => category.indexOf(m.name) !== -1)
  //     .map((m) => m._id);

  //   setCatList(x);
  // }, [category]);

  const getFirstdata = () => {
    getCourses()
      .then((data) => {
        if (!data) {
          ERROR_TOAST("Error Connecting to Server !");
          setCourseRow([]);
        }
        setCourseRow(data);
      })
      .catch((e) => {
        console.log(e);
      });

    getCourseFillDetails()
      .then((data) => {
        setList(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event: SelectChangeEvent<typeof category>) => {
    const {
      target: { value },
    } = event;
    console.log(event);
    setCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCourse = (event: SelectChangeEvent<typeof course>) => {
    const {
      target: { value },
    } = event;
    console.log(event);
    setCourse(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const setData = (e: any) => {
    const choosen = List.filter((m: any) => m.name == e.target.value);
    setCategory(choosen[0]._id);
    setMyList(choosen);
    if (myList) {
      setShow(true);
    }
  };

  const showData = async () => {
    if (!name || name.length < 3) {
      return ERROR_TOAST("please enter the name of kid !");
    }

    if (!Gender || Gender === "") {
      return ERROR_TOAST("please enter the gender of kid !");
    }

    if (!Age) {
      return ERROR_TOAST("please enter the Age of kid !");
    }

    if (!category || category.length < 1) {
      return ERROR_TOAST("please add a course category !");
    }

    if (!mobile || mobile.length < 10) {
      return ERROR_TOAST("please enter a valid mobile number !");
    }

    if (!schoolName || schoolName == "") {
      return ERROR_TOAST("please enter the school name!");
    }

    if (!parents || parents == "") {
      return ERROR_TOAST("please enter the parent's name !");
    }

    if (!city || city == "") {
      return ERROR_TOAST("please enter the city name !");
    }
    if (!course) {
      return ERROR_TOAST("please select the course !");
    }

    const data = {
      name: name,
      gender: Gender,
      age: Age,
      interests: category,
      courses: course,
      contact: mobile,
      school: schoolName,
      parents_name: parents,
      city,
    };

    const result = await createStudent(data);

    if (result.status === "failed") {
      return ERROR_TOAST(result.message);
    }

    if (result.message === "Phone number already linked with another account") {
      return ERROR_TOAST(result.message);
    }

    if (result.response.message === "user created successfully") {
      SUCCESS_TOAST("kid's data added successfully");
      setTimeout(() => {
        handleClick();
      }, 2000);
    }
  };

  const updateData = async () => {
    if (!name || name.length < 3) {
      return ERROR_TOAST("please enter the name of kid !");
    }

    if (!Gender || Gender === "") {
      return ERROR_TOAST("please enter the gender of kid !");
    }

    if (!Age) {
      return ERROR_TOAST("please enter the Age of kid !");
    }

    if (!category || category.length < 1) {
      return ERROR_TOAST("please add a course category !");
    }

    if (!mobile || mobile.length < 10) {
      return ERROR_TOAST("please enter a valid mobile number !");
    }

    if (!schoolName || schoolName == "") {
      return ERROR_TOAST("please enter the school name!");
    }

    if (!parents || parents == "") {
      return ERROR_TOAST("please enter the parent's name !");
    }

    if (!city || city == "") {
      return ERROR_TOAST("please enter the city name !");
    }

    if (!course) {
      return ERROR_TOAST("please enter select the course !");
    }

    const data = {
      _id: props.data?._id,
      name: name !== props.data?.name ? name : props.data?.name,
      gender: Gender !== props.data?.gender ? Gender : props.data?.gender,
      age: Age !== props.data?.age ? Age : props.data?.age,
      interests:
        category !== props.data?.interests ? category : props.data.interests,
      contact: mobile !== props.data?.contact ? mobile : props.data.contact,
      school:
        schoolName !== props.data?.school ? schoolName : props.data.school,
      courses: course,
      parents_name:
        parents !== props.data?.parents_name
          ? parents
          : props.data.parents_name,
      city: city !== props.data?.city ? city : props.data.city,
    };
    console.log(data);
    const result = await updateKid(data);
    console.log(result);
    SUCCESS_TOAST("kid have been updated successfully !");
    setTimeout(() => {
      handleClick();
    }, 2000);
  };

  return (
    <>
      <div className="addKids">
        <div className="fullDesc">
          <div className="headerAK">
            <h2>{props.data ? "Update Kids" : "Add kid"}</h2>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="ac-border-top"></div>
          <div className="ad-body">
            <div className="previous-drop">
              {/* <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Unique ID
                </label>
                <input
                  className="unique_id"
                  aria-label="Default select example"
                  name="Category"
                  onChange={setData}
                />
              </div> */}
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Kids Name*
                </label>
                <input
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Age(In Years)*
                </label>
                <input
                  className="form-select setThird"
                  aria-label="Default select example"
                  name="age"
                  value={Age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
              <div className="radios ">
                <div>
                  <label className="cat-hello mx-1" htmlFor="cat">
                    Gender*
                  </label>
                  <div className="radio-child ">
                    <label className="align-label px-2">
                      <input
                        type="radio"
                        name="choice-radio"
                        value="male"
                        checked={Gender === "male"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <span className="mx-2"> Boy</span>
                    </label>
                    <label className="align-label px-4">
                      <input
                        type="radio"
                        name="choice-radio"
                        value="female"
                        checked={Gender === "female"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <span className="mx-2">Girl</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="next-drop-kid">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Parents Name*
                </label>
                <input
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="ParentsName"
                  value={parents}
                  onChange={(e) => setParents(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Mobile Number*
                </label>
                <input
                  type={"number"}
                  maxLength={10}
                  value={mobile}
                  className="mobile_number"
                  aria-label="Default select example"
                  name="mobileNumber"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Course Category*
                </label>
                <FormControl sx={{ m: 0.5, width: 300 }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={category}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.length + " selected"}
                    MenuProps={MenuProps}
                  >
                    {List.map((m) => (
                      <MenuItem key={m._id} value={m._id}>
                        <Checkbox checked={category.indexOf(m._id) > -1} />
                        <ListItemText primary={m.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="next-drop-kid">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  School Name*
                </label>
                <input
                  type="text"
                  name="school"
                  value={schoolName}
                  className="setSecond-kid form-control dropdown-toggle"
                  onChange={(e) => setSchoolName(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label
                  className="cat-hello"
                  style={{ marginLeft: 40 }}
                  htmlFor="cname"
                >
                  City*
                </label>
                <input
                  type="text"
                  name="city"
                  value={city}
                  className="setCity form-control dropdown-toggle"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Course Name*
                </label>
                {/* <select
                  className="form-select setFirst-kid form-control"
                  aria-label="Default select example"
                  name="Certification Levels"
                  value={course}
                  onChange={(e) => setCourse([e.target.value, ...course])}
                >
                  {!props.data && (
                    <option style={{ color: "#8e8ea1" }} selected>
                      Choose Course
                    </option>
                  )}

                  {courseRow.map((m: any) => (
                    <option
                      key={m._id}
                      value={m._id}
                      selected={m._id == course}
                    >
                      {m.name}
                    </option>
                  ))}
                </select> */}
                <FormControl sx={{ m: 0.5, width: 300 }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={course}
                    onChange={handleCourse}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.length + " selected"}
                    MenuProps={MenuProps}
                  >
                    {courseRow.map((m) => (
                      <MenuItem key={m._id} value={m._id}>
                        <Checkbox checked={course.indexOf(m._id) > -1} />
                        <ListItemText primary={m.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <FormControl key={2} sx={{ m: 2, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                  <Select
                    // labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    className="form-select setFirst1"
                    style={!choosenCourse ? { height: 50 } : {}}
                    multiple
                    value={course}
                    onChange={handleCourseChange}
                    // input={
                    //   <OutlinedInput id="select-multiple-chip" label="Chip" />
                    // }
                    renderValue={(selectedCourse) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selectedCourse.map((value, i) => (
                          <>
                            {i > 2
                              ? setChoosenCourse(true)
                              : setChoosenCourse(false)}
                            <Chip key={i} label={value} />
                          </>
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {courseRow.map((m) => (
                      <MenuItem
                        key={m._id}
                        value={m.name}
                        style={getStyles(m.name, course, theme)}
                      >
                        {m.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </div>
            </div>
          </div>

          <div className="drag-drop-top-line"></div>
          <div className="down-buttons d-flex">
            <button
              className="create-button"
              id="submit"
              onClick={() => {
                props.data ? updateData() : showData();
              }}
            >
              {props.data ? "Update Kid" : "Add kid"}
            </button>
            <button className="cancel-button" onClick={() => handleClick()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
