import React from 'react'
import { useEffect } from 'react';
import { getChallenges } from '../../API/challenges';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Popover } from '@mui/material';
import { useStyles } from './eventsAndChallengesStyles'
import { AddChallenge } from './AddChallenge';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
// import './bootstrap.css'
// import './eventsAndChallenges.css'

const ChallengesManagement: React.FC = ({ }) => {

    const [challenges, setChallenges] = React.useState<any>([]);
    const [challengeToEdit, setChallengeToEdit] = React.useState(undefined);
    const [rows, setRows] = React.useState<any>([]);
    const [modalShow, setModalShow] = React.useState<boolean>(false);
    const [changeIsActive, setChangeIsActive] = React.useState<boolean>(false);
    const columns = [
        "Event Name",
        "Age Group",
        "Event Type",
        "Start Date",
        "End Data",
        "Mode",
        "Status",
        "Action",
        ""
    ]

    const getChallenge = React.useCallback(async () => {
        const newChallenges = await getChallenges()
        setChallenges(newChallenges);
    }, [])

    useEffect(() => {
        getChallenge()
    }, [modalShow]);

    useEffect(() => {
        if (challenges) {
            const newChallenges = challenges.map((challenge: any) => {
                const newChallenge = {
                    "_id": challenge._id as string,
                    "title": challenge.title as string,
                    "ageGroup": `${challenge.minAge} Yrs-${challenge.maxAge} Yrs`,
                    "eventType": "Activity",
                    "startDate": challenge.startDate ? challenge.startDate as string : "2021-01-01",
                    "endDate": challenge.endDate ? challenge.endDate as string : "2021-01-01",
                    "mode": challenge.mode as string,
                    "status": challenge.isActive ? "Active" : "Inactive",
                    "action": challenge.isActive ? "Deactivate" : "Activate"
                }
                return newChallenge;
            })
            setRows(newChallenges);
        }
    }, [challenges])

    const classes = useStyles();

    const handleUpdate = (id: string, toChangeIsActive: boolean) => {
        setChangeIsActive(toChangeIsActive)
        setChallengeToEdit(challenges.find((challenge: any) => challenge._id == id))
        setModalShow(true)
    }

    const handleClose = () => {
        setModalShow(false);
        setChallengeToEdit(undefined);
        setChangeIsActive(false);
    }

    return (
        <div>
            <div className='tableHeader active-courses'>
                <h1></h1>
                <>
                    <button className="addChallengeBtn" onClick={() => setModalShow(true)}>
                        <div className="btnContainer">
                            <span>Add Challenge</span>
                            <span>
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.25 5.25H6.75V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790178 6.19891 0 6 0C5.80109 0 5.61032 0.0790178 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V5.25H0.75C0.551088 5.25 0.360322 5.32902 0.21967 5.46967C0.0790178 5.61032 0 5.80109 0 6C0 6.19891 0.0790178 6.38968 0.21967 6.53033C0.360322 6.67098 0.551088 6.75 0.75 6.75H5.25V11.25C5.25 11.4489 5.32902 11.6397 5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803C6.67098 11.6397 6.75 11.4489 6.75 11.25V6.75H11.25C11.4489 6.75 11.6397 6.67098 11.7803 6.53033C11.921 6.38968 12 6.19891 12 6C12 5.80109 11.921 5.61032 11.7803 5.46967C11.6397 5.32902 11.4489 5.25 11.25 5.25Z"
                                        fill="#EB1556"
                                    />
                                </svg>
                            </span>
                        </div>
                    </button>

                    {modalShow && <AddChallenge data={challengeToEdit} handleClose={handleClose} changeIsActive={changeIsActive} />}
                </>
            </div>
            <div>
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((col) => {
                                    return (
                                        <TableCell key={col} align="center" className={classes.colTitleCell}
                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <span className={classes.colTitle}>{col}</span>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any) => (
                                <TableRow
                                    key={row._id}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="center" className="tableCell">
                                        <div className={"divBtn"}>
                                            <span className='divBtnText'>{row.ageGroup}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">{row.eventType}</TableCell>
                                    <TableCell align="center">{row.startDate}</TableCell>
                                    <TableCell align="center">{row.endDate}</TableCell>
                                    <TableCell align="center">{row.mode}</TableCell>
                                    <TableCell align="center">
                                        <div className={`divBtn ${row.status == "Active" ? "active" : "inActive"}`}>
                                            {row.status}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <button className={`divBtn ${row.action == "Activate" ? "activate" : "deactivate"}`}
                                            onClick={() => handleUpdate(row._id, true)}
                                        >
                                            {row.action == "Activate" ?
                                                <UploadIcon sx={{ color: "grey" }} />
                                                :
                                                <DownloadIcon sx={{ color: "grey" }} />
                                            }

                                            {row.action}
                                        </button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <button onClick={() => handleUpdate(row._id, false)} className={"btn btn-light repair"}>
                                            <ModeEditIcon sx={{ color: "grey" }} />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div >
    );
}

export default ChallengesManagement; 