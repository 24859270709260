import * as React from "react";
import { Chip, ChipProps } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { red } from "@mui/material/colors";
import AddCourse from "../../components/addNewCourse/addCourse";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { ERROR_TOAST, INFO_TOAST } from "../../helpers/toasters";
import { getCourses, getCoursesBysearch } from "../../API/courses";
import MainTable from "../../components/Datagrid/dataGridTable";
export type CourseData = {
  active: boolean;
  assessment: boolean;
  assessment_levels: string[];
  category: string;
  certification_levels: string[];
  course_completion: boolean;
  course_id: string;
  name: string;
  report_card: string;
  testimonials: string[];
  __v: Number;
  _id: string;
};

export const CourseinitialUpdatingRow = {
  active: false,
  assessment: true,
  assessment_levels: [],
  category: "",
  certification_levels: [],
  course_completion: false,
  course_id: "",
  name: "",
  report_card: "",
  testimonials: [],
  __v: 0,
  _id: "",
};

export default function ActiveCoursesList(props: any) {
  const [rows, setRows] = React.useState<String[]>([]);
  const [searchRows, setSearchRows] = React.useState<String[]>([]);
  const [updateCourse, setUpdateCourse] = React.useState(false);
  const [refreshPage, setRefreshPage] = React.useState(false);
  const [updatingRow, setUpdatingRow] = React.useState<CourseData>(
    CourseinitialUpdatingRow
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.search.length > 0) {
      getCoursesBysearch(props.search)
        .then((data) => {
          if (data.message == "Not Found") {
            return INFO_TOAST(`Course Not Found With '${props.search}' Name!`);
          }
          setSearchRows(data.data);
        })
        .catch((e) => ERROR_TOAST("Something went wrong !"));
    }
    if (props.search.length == 0) {
      setSearchRows([]);
    }
  }, [props.search]);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getCourses()
      .then((data) => {
        if (!data) {
          ERROR_TOAST("Error Connecting to Server !");
          setRows([]);
        }
        setRows(data);
        setSearchRows([...rows]);
        setLoading(false);
      })
      .catch((e) => {
        return ERROR_TOAST("Error Connecting to Server !");
      });
  };

  function getChipProps(params: GridRenderCellParams): ChipProps {
    if (params.value == false) {
      return {
        // icon: <WarningIcon style={{ fill: red[500] }} />,
        label: "Inactive",
        style: {
          background: red[700],
          width: 500,
          height: "30px",
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          borderColor: red[700],
        },
      };
    } else {
      return {
        label: "Active",
        style: {
          borderColor: "#5ED7C3",
          width: 500,
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          background: "#5ED7C3",
          // #5ED7C3
        },
      };
    }
  }

  function getChipProps2(params: GridRenderCellParams): ChipProps {
    if (params.value == false) {
      return {
        icon: <ReportOutlinedIcon style={{ fill: "#fff" }} />,
        label: "Pending",
        style: {
          background: "#FFB74A",
          width: 600,
          height: "30px",
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          borderColor: "#FFB74A",
        },
      };
    } else {
      return {
        icon: <CheckCircleOutlinedIcon style={{ fill: "#fff" }} />,
        label: "Sent",
        style: {
          borderColor: "#7A9BF9",
          width: 500,
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          background: "#7A9BF9",
          // #5ED7C3
        },
      };
    }
  }

  const columns = [
    {
      field: "_id",
      hide: true,
    },
    {
      field: "course_id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "name",
      headerName: "Name",
      width: 130,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      dataGeneratorUniquenessEnabled: true,
      valueGetter: (params) => params.value.name,
      width: 100,
      editable: false,
    },
    {
      field: "assessment",
      headerName: "Assesments",
      valueGetter: (params) => (params.value ? "Yes" : "No"),
      dataGeneratorUniquenessEnabled: true,
      width: 90,
      editable: false,
    },
    {
      field: "assessment_levels",
      headerName: "Ass Levels",
      valueGetter: (params) => params.value.length,
      dataGeneratorUniquenessEnabled: true,
      width: 90,
      editable: false,
    },
    {
      field: "certification_levels",
      headerName: "Cert Level",
      valueGetter: (params) => params.value.length,
      width: 90,
      editable: false,
    },
    {
      field: "report_card",
      headerName: "Report Card ",
      type: "boolean",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps2(params)} />
        );
      },
      width: 100,
      editable: false,
    },
    {
      field: "certificate",
      headerName: "Certificate ",
      type: "boolean",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps2(params)} />
        );
      },
      width: 100,
      editable: false,
    },
    {
      field: "course_completion",
      headerName: "Completion ",
      valueGetter: (params) => (params.value === true ? "Yes" : "No"),
      width: 100,
      editable: false,
    },
    {
      field: "active",
      headerName: "Status ",
      description: "Status",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
      width: 100,
      editable: false,
    },
    {
      field: "",
      width: 40,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
        );
      },
    },
  ];

  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    console.log("clicked");
    setUpdateCourse(true);
    setUpdatingRow(row);
  };

  const handelClose = () => {
    getData();
    setUpdateCourse(false);
  };

  return (
    <>
      <MainTable
        rows={searchRows.length > 0 ? searchRows : rows}
        columns={columns}
        loading={loading}
      />
      {updateCourse && (
        <AddCourse handelClose={handelClose} data={updatingRow} />
      )}
    </>
  );
}
