import { call } from "../helpers/axiosInterceptor";

const addAttendence = async (payload: any) => {
  try {
    const { data } = await call.post("attendence", { ...payload },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getAttendence = async (batch_id: any, payload: any) => {
  try {
    const { data } = await call.post(`attendence/list/${batch_id}`, {
      ...payload,
    },{headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const unMarkAttendence = async (_id: any, user: any,date:any) => {
  try {
    console.log(date);
    const { data } = await call.get(`attendence/unmark/${_id}/${user}/${date}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getAttendenceByDateAndBatch = async (batch_id: any, payload: any) => {
  try {
    const { data } = await call.post(`attendence/batchwise/list/${batch_id}`, {
      ...payload,
    },{headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export { addAttendence, getAttendence, unMarkAttendence ,getAttendenceByDateAndBatch};
