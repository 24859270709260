import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createBatche, getNewBatcheID, updateBatch } from "../../API/Batches";
import { getCoachesList } from "../../API/coach-mgmt";
import { getCourseFillDetails, getCourses } from "../../API/courses";
import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import { CourseCategoryData } from "../../pages/Batches/batchList";
import { CourseData } from "../../pages/courses/CoursesList";

import AddBatchStudentButton from "./addStudents/addStudentButton";
import "./createBatch.css";

type data = {
  days_type: string;
  type: string;
  active: boolean;
  course: CourseData[];
  course_category: [CourseCategoryData];
  students: string[];
  timeslot: string;
  coach: string;
  repeats: number[];
  batch_id: String;
  _id: string;
};

interface handelFuncProps {
  handelClose: () => void;
  data?: data;
}

export default function CreateBatch(props: any) {
  const [active, setOpen] = useState(false);
  const [batchSlot, setBatchSlot] = useState(
    props.data?.days_type || "Weekend"
  );
  const [selected, setSelected] = useState<string[]>(
    props.data?.students.map((m) => m._id) || []
  );
  const [CategoryList, setCategoryList] = useState<any>([]);
  const [Category, setCategory] = useState<any>(
    props.data?.course_category[0]._id || ""
  );
  const [batchType, setBatchType] = useState<any>(
    props.data?.type || "Evening"
  );
  const [days, setDays] = useState<any>(
    props.data?.repeats[0] == 1
      ? "Monday-Friday"
      : "Saturday-Sunday" || "Saturday-Sunday"
  );
  const [CoachList, setCoachList] = useState<any>([]);
  const [Coach, setCoach] = useState<any>(props.data?.coach[0]._id || []);
  const [CourseList, setCourseList] = useState<any>([]);
  const [subCourseList, setSubCourseList] = useState<any>([]);
  const [Course, setCourse] = useState<any>(props.data?.course || "");
  const [BatchId, setBatchId] = useState<any>(props.data?.batch_id || 0);
  const [timeSlot, setTimeSlot] = useState<any>(props.data?.timeslot || "");
  const [students, setStudents] = useState<any>(
    props.data?.students.map((m) => m._id) || []
  );
    const Customtimeslots=[
    "7:00 AM - 8:00 AM",
    "8:00 AM - 9:00 AM",
    "9:00 AM - 10:00 AM",
    "9:00 AM - 11:30 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "11:30 AM - 1:00 PM",
    "12:00 PM - 1:00 PM",
    "1:00 PM - 2:00 PM",
    "2:00 PM - 3:00 PM",
    "3:00 PM - 4:00 PM",
    "3:30 PM - 5:00 PM",
    "4:00 PM - 5:00 PM",
    "5:00 PM - 6:00 PM",
    "5:00 PM - 6:30 PM",
    "5:30 PM - 6:00 PM",
    "6:00 PM - 7:00 PM",
    "6:00 PM - 7:30 PM",
    "7:00 PM - 8:00 PM",
    "7:30 PM - 9:00 PM",
    "8:00 PM - 9:00 PM",
    "8:00 PM - 9:30 PM",
    "9:00 PM - 10:00 PM",

    ]
  useEffect(() => {
    if (props.data) {
      setCoach(props.data?.coach.map((m: any) => m._id));
    }

    getFirstdata();
  }, []);

  useEffect(() => {
    getNewBatcheID(Course)
      .then((data) => setBatchId(data))
      .catch((e) => ERROR_TOAST("Error in getting batch id"));
  }, [Course]);

  const handleChange = (event: SelectChangeEvent<typeof Coach>) => {
    const {
      target: { value },
    } = event;
    console.log(event);
    setCoach(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (props.data) {
      getCat();
    } else {
      setSubCourseList([
        ...CourseList.filter((m) => Category === m.category._id),
      ]);
    }
  }, [Category]);

  const getCat = async () => {
    const data = await getCourses();
    if (!data) {
      ERROR_TOAST("Error Connecting to Server !");
    }

    setSubCourseList([
      ...data.filter(
        (m, i) => props.data?.course_category[0]._id === m.category._id
      ),
    ]);
  };

  const submitData = async () => {
    if (!BatchId) {
      return INFO_TOAST("please try again !");
    }

    if (!Category) {
      return INFO_TOAST("please choose a Category !");
    }

    if (!Course) {
      return INFO_TOAST("please choose a course !");
    }

    if (!batchType) {
      return INFO_TOAST("please choose a Batch Type !");
    }

    if (!Coach) {
      return INFO_TOAST("please choose a Coach !");
    }

    if (!timeSlot) {
      return INFO_TOAST("please choose a Time Slot!");
    }

    if (!students || students.length < 0) {
      return INFO_TOAST("please select students!");
    }

    const data = {
      batch_id: BatchId,
      course: Course,
      course_category: Category,
      type: batchType,
      active: true,
      coach: Coach,
      days_type: batchSlot,
      repeats: batchSlot === "Weekend" ? [6, 0] : [1, 5],
      timeslot: timeSlot,
      students,
    };

    const result = await createBatche(data);
    if (result.message === "success") {
      SUCCESS_TOAST("Batch Created Successfully !");
      setTimeout(() => {
        handleClick();
      }, 2000);
    } else {
      return ERROR_TOAST("Please try again, Batch not created !");
    }
  };

  const getFirstdata = () => {
    getCourseFillDetails()
      .then((data) => {
        setCategoryList(data);
      })
      .catch((e) => {
        console.log(e);
      });

    getCoachesList()
      .then((data) => {
        setCoachList(data);
      })
      .catch((e) => {
        console.log(e);
      });

    getCourses()
      .then((data) => {
        if (!data) {
          ERROR_TOAST("Error Connecting to Server !");
        }
        setCourseList([...data.filter((m) => m.active)]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClick = () => {
    return props.handelClose();
  };

  const handleSubmit = () => {
    setStudents(selected);
    return;
  };

  // useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  const onSelect = (e) => {
    if (e.nativeEvent.target.checked) {
      setSelected([e.target.value, ...selected]);
    } else {
      setSelected([
        ...selected.slice(0, selected.indexOf(e.target.value)),
        ...selected.slice(
          selected.indexOf(e.target.value) + 1,
          selected.length
        ),
      ]);
    }
  };

  const updateData = async () => {
    const data = {
      _id: props.data._id,
      batch_id: BatchId,
      course: Course,
      course_category: Category,
      type: batchType,
      active: true,
      coach: Coach,
      days_type: batchSlot,
      repeats: batchSlot === "Weekend" ? [6, 0] : [1, 5],
      timeslot: timeSlot,
      students,
    };

    console.log(data);

    const result = await updateBatch(data);

    if (!result) {
      return ERROR_TOAST("Something wrong happened");
    }

    if (result.message == "success") {
      SUCCESS_TOAST(`Batch (${result.data.batch_id}) Updated Successfully !`);
    }

    setTimeout(() => {
      handleClick();
    }, 2000);
  };

  return (
    <>
      <div className="addCourse">
        <div className="fullDesc">
          <div className="headerAC">
            <h2>{props.data ? "Update Batch" : "Create Batch"}</h2>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="ac-border-top"></div>
          <div className="ad-body">
            <div className="previous-drop">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Choose Categoty*
                </label>
                <select
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="Certification Levels"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {!props.data && (
                    <option style={{ color: "#8e8ea1" }} selected>
                      Choose Batch Categoty{" "}
                    </option>
                  )}
                  {CategoryList.map((m: any) => (
                    <option
                      key={m._id}
                      value={m._id}
                      selected={m._id === Category}
                    >
                      {m.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Course Name*
                </label>
                <select
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="Certification Levels"
                  onChange={(e) => setCourse(e.target.value)}
                >
                  {!props.data && (
                    <option style={{ color: "#8e8ea1" }} selected>
                      Choose Course*
                    </option>
                  )}

                  {subCourseList.map((m: any) => (
                    <option
                      key={m._id}
                      value={m._id}
                      selected={m._id == Course}
                    >
                      {m.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="next">
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Batch Type*
              </label>
              <select
                className="form-select setThird setBatchThird"
                aria-label="Default select example"
                name="Certification Levels"
                onChange={(e) => setBatchType(e.target.value)}
                value={batchType}
              >
                <option>Evening</option>
                <option>Morning</option>
                <option>Afternoon</option>
              </select>
            </div>
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Batch Slot*
              </label>
              <select
                className="form-select setThird setSlotWidth"
                aria-label="Default select example"
                name="Certification Levels"
                onChange={(e) => setBatchSlot(e.target.value)}
              >
                <option selected={props.data?.days_type == "Weekend"}>
                  Weekend
                </option>
                <option selected={props.data?.days_type == "Weekday"}>
                  Weekday
                </option>
              </select>
            </div>
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Days
              </label>
              <select
                className="form-select setThird setSlotWidth"
                aria-label="Default select example"
                name="Certification Levels"
                disabled
                onChange={(e) => setDays(e.target.value)}
              >
                <option selected={batchSlot === "Weekend"}>
                  Saturday-Sunday
                </option>
                <option selected={batchSlot === "Weekday"}>
                  Monday-Friday
                </option>
              </select>
            </div>
          </div>
          <div className="next-3">
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Time Slot*
              </label>
              <select
                className="form-select setThird setBatchThird"
                aria-label="Default select example"
                name="Certification Levels"
                onChange={(e) => setTimeSlot(e.target.value)}
              >
                {!props.data && (
                  <option style={{ color: "#8e8ea1" }} selected>
                    Choose Batch Slot*
                  </option>
                )}
                {/* <option>7:00 AM - 8:00 AM</option>
                <option>10:00 AM - 12:00 PM</option>
                <option>2:00 PM - 4:00 PM</option>
                <option>6:00 PM - 8:00 PM</option> */}
                {Customtimeslots.map((m: any) => (
                  <option key={m} value={m} selected={m == timeSlot}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="dropdown" style={{ marginRight: 50 }}>
              <FormControl sx={{ m: 0.5, width: 260 }}>
                <label className="cat-hello" htmlFor="cat">
                  Coach / Mentor*
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // className="setFirst"
                  multiple
                  value={Coach}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.length + " selected"}
                  // MenuProps={MenuProps}
                >
                  {CoachList.map((m: any) => (
                    <MenuItem key={m._id} value={m._id}>
                      <Checkbox checked={Coach.indexOf(m._id) > -1} />
                      <ListItemText primary={m.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="dropdown">
              <div className="add-btn-batch">
                <AddBatchStudentButton
                  onSelect={onSelect}
                  handleSubmit={handleSubmit}
                  rows={students}
                />
              </div>
              <div className="selected-batch-students">
                <p>
                  <b>{students.length}</b> Students added
                </p>
              </div>
            </div>
          </div>
          <div className="down-buttons d-flex">
            <button
              className="create-button"
              onClick={() => {
                props.data ? updateData() : submitData();
              }}
            >
              {props.data ? "Update Batch" : "Create Batch"}
            </button>
            <button className="cancel-button" onClick={() => handleClick()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
