import { PayloadAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import { SET_AUTH, DEL_AUTH } from "../actions/auth";

interface State {
  email: string;
  password: string;
  name: string;
  _id: string;
  isAdmin: boolean;
  accessToken: string;
  refToken: string;
}

const authReducer = (state: any = {}, action: PayloadAction<Object>) => {
  switch (action.type) {
    case REHYDRATE:
      return state;
    case SET_AUTH:
      return {
        ...state,
        ...action.payload,
      };
    case DEL_AUTH:
      return {};
    default:
      return state;
  }
};

export default authReducer;
