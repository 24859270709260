import { call } from "../helpers/axiosInterceptor";

const getBatches = async () => {
  try {
    const { data } = await call.get("batch",
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const createBatche = async (payload: any) => {
  try {
    const { data } = await call.post("batch", payload,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getNewBatcheID = async (course) => {
  try {
    console.log(course);
    const { data } = await call.get(`batch/get/id/${course}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};
const getBatchById = async (id:any) => {
  try {
    console.log(id);
    const { data } = await call.get(`batch/${id}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getAdminbatches = async (id) => {
  const { data } = await call.get(`batch/search/admin-batch/${id}`,
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};

const updateBatch = async (payload: any) => {
  try {
    const { data } = await call.post("batch/update", payload,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getBatchesBysearch = async (query) => {
  try {
    const { data } = await call.get(`batch/search/${query}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const deleteBatch = async (id:any) => {
  try {
    const { data } = await call.delete(`batch/delete/${id}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};


export {
  getBatches,
  getNewBatcheID,
  createBatche,
  updateBatch,
  getBatchesBysearch,
  getAdminbatches,
  getBatchById,
  deleteBatch
};
