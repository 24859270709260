import * as React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { delUser } from "../../store/actions";
import "./userData.css";

export default function BasicMenu() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.auth);

  const handelLogout = () => {
    dispatch(delUser());
    localStorage.removeItem("refToken");
  };

  return (
    <div className="dropdown">
      <button
        className="btn user-btn dropdown-toggle "
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="btn-text">{user.name}</span>
        <span className="btn-role">
          {user.isCoach ? "Coach" : user.isSuper ? "Super Admin" : "Admin"}
        </span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a className="dropdown-item" href="#">
            Profile
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Settings
          </a>
        </li>
        <li>
          <button className="dropdown-item" onClick={() => handelLogout()}>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
}
