import React,{useState} from "react";
import AllocateStudCard from "../allocateCard/allocateCard";
import "./allocateCard.css"
interface AllButtonProp {
    value?: string;
  }
  
  export default function AllocateCard(props: AllButtonProp) {
    const [allocateBut, setAllocateBut] = useState(false);
  
    const handelClose = () => {
      setAllocateBut(false);
    };
  
    return (
      <>
        <button className="alloBtnCard-student" onClick={() => setAllocateBut(!allocateBut)}>
          <div className="btn-inside-student">
            <span>Allocate Card</span>
            
          </div>
        </button>
  
        {allocateBut && <AllocateStudCard handelClose={handelClose} />}
      </>
    );
  }
  