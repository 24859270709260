import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    colTitle: {
        fontWeight: "bold",
        // borderWidth: "1px",
        // borderColor: "black"
    },
    colTitleCell: {
        borderWidth: 1,
        borderColor: "blue"
    },
});

export { useStyles }; 