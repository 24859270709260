import * as React from "react";
import { Alert, Chip, ChipProps } from "@mui/material";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { getBatches } from "../../API/Batches";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { DeleteOutlineRounded } from "@mui/icons-material";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import CreateBatch from "../../components/createNewBatch/createBatch";
import { CourseData, CourseinitialUpdatingRow } from "../courses/CoursesList";
import MainTable from "../../components/Datagrid/dataGridTable";

// import { Toast } from "react-toastify/dist/components";
import AlertDialog from "../../helpers/alertDialog";




function getChipProps(params: GridRenderCellParams): ChipProps {
  return {
    label: params.value[0] == 6 ? "sat-sun" : "mon-fri",
    style: {
      background: "#7A9BF9",
      width: 500,
      height: "30px",
      color: "#fff",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16px",
      borderColor: "#7A9BF9",
    },
  };
}

function getChipProps2(params: GridRenderCellParams): ChipProps {
  return {
    label: params.value,
    style: {
      background: "#7A9BF9",
      width: 500,
      height: "30px",
      color: "#fff",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16px",
      borderColor: "#7A9BF9",
    },
  };
}

export type CourseCategoryData = {
  _id: string;
};

type data = {
  days_type: string;
  type: string;
  active: boolean;
  course: CourseData[];
  course_category: [CourseCategoryData];
  students: string[];
  timeslot: string;
  coach: string;
  batch_id: String;
  repeats: number[];
  _id: string;
};

// export const initialCourseCategoty = {
//   _id: "",
// };

// const initialUpdatingRow = {
//   days_type: "",
//   type: "",
//   active: true,
//   course: [
//     {
//       ...CourseinitialUpdatingRow,
//     },
//   ],
//   course_category: [
//     {
//       ...initialCourseCategoty,
//     },
//   ],
//   students: [],
//   timeslot: "",
//   coach: "",
//   batch_id: 0,
//   repeats: [],
//   _id: "",
// };

export default function BatchList(props) {
  
  const initialUpdatingRow = {
    uniqueid: Number,
    days_type: String,
    type: String,
    active: Boolean,
    course: [],
    course_category: [],
    students: [],
    timeslot: String,
    coach: String,
    batch_id: Number,
    repeats: [],
    _id: String,
  };
  const [pageSize, setPageSize] = React.useState<number>(5);
  const [rows, setRows] = React.useState<String[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [updateCourse, setUpdateCourse] = React.useState(false);
  const [updatingRow, setUpdatingRow] = React.useState<any>(initialUpdatingRow);
  const [searchRows, setSearchRows] = React.useState<String[]>([]);
  const [BatchDeleted, setBatchDeleted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [Batch_id, setBatch_id] = React.useState<String>("");

  React.useEffect(() => {
    getData();
  }, [BatchDeleted]);
  
 
  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    setUpdateCourse(true);
    setUpdatingRow(row);
  };
  const handleDeleteClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    console.log(row._id);
    setBatch_id(row._id);
    setOpen(true);
    getData();
    
  };

  

  const getData =  () => {
    getBatches().then((res) => {
      if (res) {
         console.log(res);
        setRows(res);
        setLoading(false);
      }
     else {
      return ERROR_TOAST("Server error, Please refresh the page !");
    }
    });
  };

  const columns: GridColumns = [
    {
      field: "_id",
      hide: true,
    },
    {
      field: "batch_id",
      headerName: "Batch ID",
      width: 80,
    },
    {
      field: "course",
      headerName: "Course Name",
      valueGetter: (params) => params.value[0].name,
      
      width: 140,
      editable: true,
    },
    {
      field: "course_category",
      headerName: "Course Category",
      valueGetter: (params) => params.value[0].name,
      width: 140,
      editable: false,
    },
    {
      field: "type",
      headerName: "Batch Type",
      width: 120,
      editable: false,
    },
    {
      field: "days_type",
      headerName: "Batch Slot",
      width: 120,
      editable: false,
    },
    {
      field: "repeats",
      headerName: "Days",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
      width: 100,
      editable: false,
    },
    {
      field: "timeslot",
      headerName: "Timeslot",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps2(params)} />
        );
      },
      width: 180,
      editable: false,
    },
    {
      field: "coach",
      headerName: "Coach / Mentor ",
      valueGetter: (params) => params.value[0].name,
      width: 120,
      editable: false,
    },
    {
      field: "Edit",
      width: 60,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
          
        );
      },
    },
    {
      field: "Delete",
      width: 60,
      renderCell: (cellValues: any) => {
        
        return (
          
          <div>
            <DeleteOutlineRounded
              style={{ color: "#8E8EA1", cursor: "pointer" }}
              onClick={(event) => {
                
                handleDeleteClick(event, cellValues);
                
              }}
            />
            {open && (
              <AlertDialog
                setDeleted={setBatchDeleted}
                open={open}
                setOpen={setOpen}
                _id={Batch_id}
                type={"Batch"}
              />)}
          </div>
            

        );
      }
    }
    
  ];

  const handelClose = () => {
    setUpdateCourse(false);
    getData();
  };

  return (
    <>
      <MainTable rows={rows} columns={columns} loading={loading} />
      {updateCourse && (
        <CreateBatch handelClose={handelClose} data={updatingRow} />
      )}
    </>
  );
}
