import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
// import { getAllStudentProfiles } from "../../API/student-mgmt";
import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { getCoachbatch } from "../../API/coach-mgmt";
import { ERROR_TOAST ,INFO_TOAST} from "../../helpers/toasters";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAdminbatches } from "../../API/Batches";
import MainTable from "../../components/Datagrid/dataGridTable";
import {getAttendenceByDateAndBatch} from '../../API/attendence'

export default function EveningBatch(props: any) {
  const [rows, setRows] = React.useState<String[]>([]);
  
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentAttendence, setCurrentAttendence] = React.useState<any[]>([]);
  

  const user = useSelector((state: RootStateOrAny) => state.auth);

  React.useEffect(() => {
    if (props.selectedBatch !== undefined) {
      if (!user.isCoach) {
        getAdminbatches(props.selectedBatch)
          .then((data) => {
            const filteredData = data.filter((m: any) => {
              return m.batch_type.toLowerCase().includes('evening') || m.batch_type.toLowerCase().includes('afternoon') ;
            });
            console.log(filteredData);
            setRows(filteredData);
          })
          .catch((e) => {
            ERROR_TOAST("Error while getting batches!");
            console.log(e);
        })
        const payload={
          date:props.date
          }
          getAttendenceByDateAndBatch(props.selectedBatch,payload)
          .then(({ data, status }) => {
          //   if (status === "Error") {
          //     return ERROR_TOAST("Error in getting attendence");
          //   }
            if(data.student.length===0){
              return INFO_TOAST("No attendence found");
              }
              else{
                  setCurrentAttendence(data.student);
                  setLoading(false);
                  console.log(currentAttendence);
              }
          })
          .catch((e) => {
              // ERROR_TOAST("Error while getting attendence!");
              setCurrentAttendence([]);
          });
      } else {
        getCoachbatch(props.selectedBatch)
          .then((data) =>{
            setRows(data.filter((m: any) => {
              return m.batch_type.toLowerCase().includes('evening') || m.batch_type.toLowerCase().includes('afternoon') ;
            }))
          }) 
          .catch((e) => ERROR_TOAST("Error while getting batches!"));
      }
    }
  }, [props.selectedBatch]);
  

  function getChipProps2(params: GridRenderCellParams): ChipProps {
    return {
      label: params.value,
      style: {
        background: "#7A9BF9",
        width: 500,
        height: "30px",
        color: "#fff",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "16px",
        borderColor: "#7A9BF9",
      },
    };
  }

  const onSelect = (e: any, params: any) => {
    console.log(params);
    if (e.target.checked) {
      props.setUnMark(true);
      props.setAttendence(params.row.student_id);

      
      // props.setAttendence([params.row.student_id, ...props.attendence]);
      //student should be cheked as attended and mark as present.
    } else {
      //student should be uncheked as not attended and unmark as absent.
      // props.setAttendence([
      //   ...props.attendence.slice(
      //     0,
      //     props.attendence.indexOf(params.row.student_id)
      //   ),
      //   ...props.attendence.slice(
      //     props.attendence.indexOf(params.row.student_id) + 1,
      //     props.attendence.length
      //   ),
      // ]);
      props.setUnMark(false);
      props.setAttendence(params.row.student_id);
    }

    props.setData({
      batch: params.row.batch_main_id,
      id: params.row.batch_id,
    });
  };

  const columns = [
    {
      field: "student_id",
      hide: true,
      editable: false,
    },
    {
      field: "student_name",
      headerName: "Student Name",
      width: 150,
      editable: false,
    },
    {
      field: "student_gender",
      headerName: "Gender",
      width: 140,
      editable: false,
    },
    {
      field: "student_age",
      headerName: "Age",
      valueGetter: (params) => params.value + " Yrs",
      width: 120,
      editable: false,
    },
    {
      field: "days",
      headerName: "Days",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
      width: 120,
      editable: false,
    },
    {
      field: "batch_id",
      headerName: "Batch Id",
      width: 120,
      editable: false,
    },
    {
      field: "batch_type",
      headerName: "batch_type",
      width: 120,
      editable: false,
      valueGetter: (params) => params.value,
    },
    {
      field: "batch_timeslot",
      headerName: "Batch Slot",
      width: 180,
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps2(params)} />
        );
      },
      editable: false,
    },
    {
      field: "course_id",
      headerName: "Course Id",
      width: 120,
      editable: false,
    },
    // params.row?.attendence[0]?.student.find(
    //   (m) => m._id === params.row.student_id
    // )
    //   ? true
    //   : false
    {
      field: "Attendence",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Checkbox
            defaultChecked={
              false
            }
            onChange={(e) => onSelect(e, params)}
            sx={{
              color: "#B9B9D6",

              "&.Mui-checked": {
                color: "#EF1456",
              },
            }}
            inputProps={{
              "aria-label": "select one",
            }}
          />
        );
      },
      editable: false,
    },
  ];

  function getChipProps(params: GridRenderCellParams): ChipProps {
    return {
      label: params.value == [6, 0] ? "Sat-Sun" : "Mon-Fri",
      style: {
        background: "#7A9BF9",
        width: "63px",
        height: "30px",
        color: "#fff",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "16px",
        borderColor: "#7A9BF9C",
      },
    };
  }
  return (
    <>
      <MainTable rows={rows} columns={columns} loading={loading} />
    </>
  );
}
