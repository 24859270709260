import React, { useEffect } from "react";
import "./StudentManagement.css";
import EveningBatch from "./EveningBatch";
import MorningBatch from "./MorningBatch";
import ShowAttendence from "./ShowAttendence";
import AllocateCard from "../../components/buttons/allocateCard";
import ManageAssesment from "../../components/buttons/manageAssesment";
// import MarkAttendance from "../../components/buttons/markAttendance";
import { RootStateOrAny, useSelector } from "react-redux";
import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import { addAttendence, unMarkAttendence } from "../../API/attendence";
import moment from "moment";
import { getCoachbatches } from "../../API/coach-mgmt";
import { getBatches } from "../../API/Batches";


const StudMgmt: React.FC = () => {
  const [showBatchs, setShowBatchs] = React.useState(false);
  const [attendence, setAttendence] = React.useState<String[]>();
  const [data, setData] = React.useState<any>({});
  const user = useSelector((state: RootStateOrAny) => state.auth);
  const [loading, setLoading] = React.useState(false);
  const [unMark, setUnMark] = React.useState(false);
  const [batches, setBatches] = React.useState([]);
  const [selectedBatch, setSelectedBatch] = React.useState<any>();
  const [morningBatch,setMorningBatch]=React.useState<String[]>();
  const [eveningBatch,seteEveningBatch]=React.useState<String[]>();
  const [date,setDate]=React.useState<String>(moment(Date.now()).format('YYYY-MM-DD'));


  useEffect(() => {
    if (user.isCoach) {
      getCoachBatche(user._id);
    } else {
      getAdminBatches();
    }
    // getBatchwiseAttendence();

    
  }, []);

  useEffect(() => {
    async function atten() {
      let d = new Date();
      const datas: any = {
        date: date,
        batch: data.batch,
        batch_id: data.id,
        student: attendence,
        time: d.getTime(),
      };
      if (user.isCoach) {
        datas.coach = user._id;
      } else {
        datas.admin = user._id;
      }
      if (unMark) {
        const result = await addAttendence(datas);
        if(result.message==="Already Marked"){
          setLoading(false);
          return ERROR_TOAST("Attendence Already Marked");
        }
        else if (result.message === "success") {

          setLoading(false);
          SUCCESS_TOAST("Attendence Marked");
          return;
        } else {
          setLoading(false);
          return ERROR_TOAST("Something went wrong please try Again !");
        }
      } else {
        console.log("mark as absent");
        const result = await unMarkAttendence(data.batch, attendence,date);
        if (result.message === "success") {
          SUCCESS_TOAST("Attendence UnMarked");
          setLoading(false);
          return;
        } else {
          setLoading(false);
          return ERROR_TOAST("Something went wrong please try Again !");
        }
      }
    }
    if (attendence != undefined) {
      atten();
    }
  }, [attendence,unMark,!unMark]);

  
    
    
  const getAdminBatches = () => {
    getBatches()
      .then((data) => {
        setBatches(data);
        setSelectedBatch(data[0]._id);
      })
      .catch((e) => ERROR_TOAST("Error while getting batches!"));
  };

  const getCoachBatche = (_id: any) => {
    getCoachbatches(_id)
      .then(({ data, status }) => {
        if (status !== "Success") {
          return ERROR_TOAST("Error in getting batches");
        }
        const filteredData1=data.filter((m: any) => {
          return m.type.toLowerCase().includes('morning');
        });
        const filteredData2=data.filter((m: any) => {
          return m.type.toLowerCase().includes('evening') || m.type.toLowerCase().includes('afternoon');
        });
        setBatches(data);
        setMorningBatch(filteredData1);
        seteEveningBatch(filteredData2);
        setSelectedBatch(data[0]._id);
      })
      .catch((e) => ERROR_TOAST("Error while getting batches!"));
  };
  const changeDate=(e:any)=>{
    setDate(e.target.value);
    // console.log(e.target.value);
  }

  return (
    <div className="header">
      <div className="batchcontainer">
        <div className="student-heading">
          <h3 className="student-title">Student Management</h3>
          <div className="student-mgmt-btn">
            <span className="allocate-card-but">
              <AllocateCard />
            </span>
            <span className="manageAssesment">
              <ManageAssesment />
            </span>
          </div>
          {/* <span className="markAttendance" onClick={() => submitData()}>
            <MarkAttendance loading={loading} />
          </span> */}
        </div>
        <div>
          <div className="morningorevening">
            <div className={"batchBtnDiv"} onClick={() => setShowBatchs(false)}>
              <p className={`batchBtn ${!showBatchs ? "activeBtn" : ""}`}>
                Morning Batch
              </p>
            </div>
            <div className={"batchBtnDiv"} onClick={() => setShowBatchs(true)}>
              <p className={`batchBtn ${showBatchs ? "activeBtn" : ""}`}>
                Evening/Afternoon 
              </p>
            </div>
          </div>
          
            

          <div className="filterBatches">
            <div>
            <input type="date" placeholder="pick a date" className="dropdown student" onChange={(e)=>changeDate(e)}  />
            </div>
          
            <div className="next-value-of-coach">
              <div className="dropdown student">
                <label className="cat-hello" htmlFor="cat">
                  Filter by
                </label>
                <select
                  className="form-select setThird filter-by"
                  aria-label="Default select example"
                  name="Course Category"
                  onChange={(e) => setSelectedBatch(e.target.value)}
                >
                  {batches.map((m: any, i) => (
                    <option key={i} value={m._id}>
                      {m.batch_id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="batch-border"></div>
        </div>
        {showBatchs ? (
          <EveningBatch
            attendence={attendence}
            setAttendence={setAttendence}
            setData={setData}
            loading={loading}
            setUnMark={setUnMark}
            setLoading={setLoading}
            selectedBatch={selectedBatch}
            date={date}
          />
        ) : (
          <MorningBatch
            attendence={attendence}
            setAttendence={setAttendence}
            setData={setData}
            loading={loading}
            setUnMark={setUnMark}
            setLoading={setLoading}
            selectedBatch={selectedBatch}
            date={date}
          />
        )}
        <ShowAttendence

          attendence={attendence}
          setAttendence={setAttendence}
          setData={setData}
          loading={loading}
          setUnMark={setUnMark}
          setLoading={setLoading}
          selectedBatch={selectedBatch}
          date={date}

        />
      </div>
    </div>
  );
};
export default StudMgmt;
