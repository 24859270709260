import React, { useEffect, useState } from "react";
import AddKids from "../../components/buttons/addKids";
import KidsList from "./KidsList";

export default function KidsMgmt() {
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="courseSetUp">
        <div className="topList">
          <h2 className="top-heading">Kids Profile Management</h2>
          <div className="search-inp">
            <button type="submit">search</button>
            <input
              className="dashboard-search"
              type="search"
              value={search}
              placeholder="Search Kids Profile..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <AddKids value="Add New Kids" />
        </div>
        <KidsList search={search} />
      </div>
    </>
  );
}
