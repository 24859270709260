import React, { useState } from "react";
import Classes from "./Classes";

import { Toaster } from "react-hot-toast";
import "./addNewCourse.css";

interface AddNewCourseProps {
  handleClose: () => void;
  data?:
    | {
        _id: string;
        chooseCategoty: string;
        status: boolean;
      }
    | undefined;
  changeIsActive: boolean;
}

export default function AddNewCourse(props: AddNewCourseProps) {
  const [status, setStatus] = useState<boolean>(props.data?.status || false);
  const handleClick = () => {
    return props.handleClose();
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            color: "black",
          },
        }}
      />

      <div className="addNew-Course">
        <div className="fullDesc-addNewCourse">
          <div className="headerofANC">
            <h3>Add New Course</h3>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="acard-border-top"></div>
          <div className="ac-body">
            <div className="first-drop-line">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Course Category
                </label>
                <select
                  name="course-category"
                  aria-label="Default select example"
                  className="form-select setThird setBatchThird"
                >
                  <option selected>Music</option>
                </select>
              </div>

              <div className="next-value">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Sub Category
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="sub-category"
                  >
                    <option selected>Guitar</option>
                  </select>
                </div>
              </div>
              <div className="next-value">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Coach/Mentor
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="coach/mentor"
                  >
                    {/* <option selected></option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="second-drop-line">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Age Group
                </label>
                <select
                  name="age group"
                  aria-label="Default select example"
                  className="form-select setThird setBatchThird"
                >
                  {/* <option selected></option> */}
                </select>
              </div>

              <div className="next-value">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Days
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="days"
                  >
                    {/* <option selected></option> */}
                  </select>
                </div>
              </div>
              <div className="next-value">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Duration
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="duration"
                  >
                    {/* <option selected></option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="third-drop-line">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Date(Optional)
                </label>
                <select
                  className="form-select setThird setBatchThird"
                  aria-label="Default select example"
                >
                  {/* <option></option> */}
                </select>
              </div>
              <div className="next-value">
                <div className="mx-3">
                  <label className="cat-hello mx-1" htmlFor="cat">
                    Mode
                  </label>
                  <div className="radio-child ">
                    <label className="align-label px-2">
                      <input
                        type="radio"
                        name="choice3"
                        checked={status}
                        onClick={() => setStatus(true)}
                      />
                      <span className="mx-2"> Online</span>
                    </label>
                    <label className="align-label px-4">
                      <input
                        type="radio"
                        name="choice3"
                        checked={!status}
                        onClick={() => setStatus(false)}
                      />

                      <span className="mx-2"> offline</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="next-step-value">
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cname">
                Overview
              </label>
              <textarea
                name="overview"
                className="setOverviewDesc form-control dropdown-toggle"
              />
            </div>
            <div className="next-step-value">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Class Structure
                </label>
                <textarea
                  name="overview"
                  className="setOverviewDesc form-control dropdown-toggle"
                />
              </div>
            </div>
          </div>
          <div className="next-step-value">
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cname">
                Upload Thumbnail
              </label>
              <div className="uplode-image-video">
                <input name="thumbnail" className="set-box-upload" />
                <button className="upload">Upload</button>
                <button className="browse">Browse</button>
              </div>
            </div>
          </div>
          <Classes />
          <div className="drag-drop-top-line"></div>
          <div className="down-buttons d-flex">
            <button className="create-button">Submit</button>
            <button className="cancel-button" onClick={() => handleClick()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
