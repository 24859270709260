import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import toast, { Toaster } from "react-hot-toast";
import { createPoints, updatePoints } from "../../API/points";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
// import { uploadPointsLogo } from "../../API/upload";
import "./addNewCard.css";
import "./upload-help.ts";
import Upload from "../../icons/upload";
type data = {
  name: string;
  description: string;
  logo: string;
  value: Number;
  __v: Number;
  _id: string;
};

interface handelFuncProps {
  handelClose: () => void;
  data?: data;
}

export default function AddnewCard(props: handelFuncProps) {
  const [url, setUrl] = useState(props.data?.logo || "");
  const [cardName, setCardName] = useState(props.data?.name || "");
  const [cardDesc, setCardDesc] = useState(props.data?.description || "");
  const [cardPoints, setCardPoints] = useState(props.data?.value || 0);
  const [cardUploadId, setCardUploadId] = useState("");
  const [cardUploadedLogos, setCardUploadedLogos] = useState<string[]>([]);

  const uri =
    process.env.NODE_ENV == "development"
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_URL;
  const notify = (data: String) => toast(data + "");

  const onError = (e: any) => {
    console.log("Error", e);
  };

  const onSuccess = (res: any) => {
    console.log(res.url);
    setCardUploadedLogos((cardUploadedLogos) => [
      res.thumbnailUrl,
      ...cardUploadedLogos,
    ]);
    console.log(res);
    setCardUploadId(res.fileId);
    setUrl(res.url);
  };

  const updateData = async () => {
    const data = {
      _id: props.data?._id,
      name: cardName !== props.data?.name ? cardName : props.data.name,
      description:
        cardDesc !== props.data?.description
          ? cardDesc
          : props.data.description,
      value: cardPoints !== props.data?.value ? cardPoints : props.data.value,
      logo: url !== props.data?.logo ? url : props.data.logo,
    };

    console.log(data);
    const result = await updatePoints(data);
    console.log(result);
    notify("points have been updated successfully !");
    handleClick();
  };

  const handleClick = () => {
    return props.handelClose();
  };

  const ShowData = async () => {
    const payload = {
      name: cardName,
      description: cardDesc,
      value: cardPoints,
      logo: url,
    };
    const result = await createPoints(payload);
    if ((result.message = "Points Created Successfully")) {
      notify("Points Created Successfully");
      setTimeout(() => {
        handleClick();
      }, 2000);
      console.log(result);
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            color: "green",
          },
        }}
      />
      <div className="addCourse">
        <div className="fullDescCard">
          <div className="headerAC">
            <h2>{props.data ? "Update Card" : "Create New Card"}</h2>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="ac-border-top"></div>
          <div className="ad-body">
            <div className="previous-drop">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Card Name*
                </label>
                <input
                  type="text"
                  value={cardName}
                  name="card_name"
                  className="setCard form-control dropdown-toggle"
                  onChange={(e) => setCardName(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Choose Point*
                </label>
                <select
                  className="form-select setFirstCard"
                  aria-label="Default select example"
                  name="Certification Levels"
                  onChange={(e) => setCardPoints(parseInt(e.target.value))}
                >
                  <option selected>{props.data?.value}</option>
                  <option selected={props.data?.value === 200}>200</option>
                  <option selected={props.data?.value === 400}>400</option>

                  <option selected={props.data?.value === 600}>600</option>

                  <option selected={props.data?.value === 800}>800</option>

                  <option selected={props.data?.value === 1000}>1000</option>
                </select>
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Upload Card*
                </label>
                <br />
                {/* <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
                <input
                  type="button"
                  className="d-flex justify-flex-end"
                  onClick={uploadFile}
                  value="Upload"
                /> */}
                <IKContext
                  publicKey="public_j5S33KH3AQ/NdlZ3jumN7sZ6AmY="
                  urlEndpoint="https://ik.imagekit.io/vecch6x6p"
                  authenticationEndpoint={uri + `card/auth`}
                >
                  <div className="upload-area">
                    <div className="upload-icon">
                      <Upload />
                    </div>
                    <div className="upload-text">
                      <p>Upload or drag & drop files here</p>
                      <p id="upload-text-second">JPG, PNG upto 10mb</p>
                    </div>
                    <IKUpload
                      fileName="my-upload"
                      onError={onError}
                      onSuccess={onSuccess}
                    />
                  </div>
                </IKContext>
                {cardUploadedLogos.map((m: any) => (
                  <>
                    <span className="uploaded-img-delete">❌</span>
                    <img
                      className="m-2 down-uploaded-img "
                      src={m}
                      alt="..."
                      height={50}
                      width={50}
                    />
                  </>
                ))}
              </div>
            </div>
            <div className="next-drop">
              <div className="dropdown set-downCard">
                <label className="cat-hello" htmlFor="cname">
                  Card Description*
                </label>
                <input
                  type="text"
                  name="course_name"
                  value={cardDesc}
                  className="setCardDesc form-control dropdown-toggle"
                  onChange={(e) => setCardDesc(e.target.value)}
                />
              </div>
            </div>
            <div className="drag-drop-top-line mt-5"></div>
            <div className="down-buttons d-flex">
              <button
                className={`create-button`}
                disabled={
                  cardDesc == "" ||
                  cardName == "" ||
                  cardPoints == 0 
                  // url == "" was removed imagekit logo url is required
                }
                onClick={() => {
                  props.data ? updateData() : ShowData();
                }}
              >
                {props.data ? "Update Card" : "Create New Card"}
              </button>
              <button className="cancel-button" onClick={() => handleClick()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
