import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, GridRenderCellParams } from "@mui/x-data-grid";
import { getCoachesList } from "../../API/coach-mgmt";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { ChipProps } from "@mui/material";
import AddNewCoach from "../../components/addNewCoach/addCoach";
import MainTable from "../../components/Datagrid/dataGridTable";

export default function CoachList() {
  const defaultCreateCoachState = {
    name: String,
    email: String,
    password: String,
    isSuper: Boolean,
    category: String,
    active: Boolean,
    trainingModel: String,
    dob: Date,
    contact: Number,
    address: {
      street: String,
      city: String,
      state: String,
      postalCode: Number,
      country: String,
    },
    city: String,
  };

  // const [createCoachData, setCreateCoachData] = useState<any>(defaultCreateCoachState)

  const [coaches, setCoaches] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [pageSize, setPageSize] = useState(5);

  const [updateCoach, setUpdateCoach] = React.useState<boolean>(false);
  const [updatingRow, setUpdatingRow] = React.useState<any>(
    defaultCreateCoachState
  );

  useEffect(() => {
    getFirstData();
  }, []);

  const getFirstData = () => {
    getCoachesList()
      .then((data) => {
        console.log(data);
        setRows(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = [
    {
      field: "_id",
      hide: true,
    },
    {
      field: "name",
      headerName: "Coach / Mentor",
      width: 140,
      editable: true,
    },
    {
      field: "category",
      headerName: "Course Category",
      dataGeneratorUniquenessEnabled: true,
      valueGetter: (params) => params.value[0].name,
      width: 140,
      editable: false,
    },
    {
      field: "trainingModel",
      headerName: "Training Model",
      dataGeneratorUniquenessEnabled: true,
      width: 130,
      editable: false,
    },
    {
      field: "contact",
      headerName: "Mobile Number",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      dataGeneratorUniquenessEnabled: true,
      width: 260,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
    },
    {
      field: "country",
      headerName: "Country",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
    },
    {
      field: "",
      width: 60,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
        );
      },
    },
  ];

  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    console.log(row);
    setUpdateCoach(true);
    setUpdatingRow(row);
  };
  const handelClose = () => {
    setUpdateCoach(false);
    getFirstData();
  };

  return (
    <>
      <MainTable rows={rows} columns={columns} loading={loading} />
      {updateCoach && (
        <AddNewCoach handelClose={handelClose} data={updatingRow} />
      )}
    </>
  );
}
