import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  getCourseFillDetails,
  createCourse,
  updateCourse,
} from "../../API/courses";
import Upload from "../../icons/upload";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
import "./addCourse.css";
import { toast } from "react-toastify";
import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";

interface handelFuncProps {
  handelClose: () => void;
  data?: {
    active: boolean;
    assessment: boolean;
    assessment_levels: string[];
    category: string;
    certification_levels: string[];
    course_completion: boolean;
    course_id: string;
    name: string;
    report_card: string;
    testimonials: string[];
    __v?: Number;
    _id: string;
  };
}

const fileTypes = ["JPG", "PNG", "GIF"];

export default function AddCourse(props: handelFuncProps) {
  const [courseName, setcourseName] = useState<string>(props.data?.name || "");
  const [certificateLevel, setCertificateLevel] = useState(
    props.data?.certification_levels.length || ""
  );
  const [AssesmentLevel, setAssesmentLevel] = useState(
    props.data?.assessment_levels.length || ""
  );
  const [category, setCategory] = useState<string>(props.data?.category || "");
  const [Assesments, setAssesments] = useState<boolean>(
    props.data?.assessment || false
  );
  const [courseCompletion, setcourseCompletion] = useState<boolean>(
    props.data?.course_completion || false
  );
  const [status, setStatus] = useState<boolean>(props.data?.active || false);
  const [show, setShow] = useState<boolean>(false);
  const [List, setList] = useState<any>([]);
  const [myList, setMyList] = useState<any>([]);
  // const [formContent, setFormContent] =
  //   useState<formContentType>(defaultFormContent);
  const [report, setReport] = useState("");
  const [certificate, setCertificate] = useState("");
  const [cardUploadId, setCardUploadId] = useState("");
  const [cardUploadedLogos, setCardUploadedLogos] = useState<string[]>([]);
  const [CertificateUrl, setCertificateUrl] = useState("");
  const [ReportCardUrl, setReportCardUrl] = useState("");
  const [refreshPage,setRefreshPage]=useState(false);

  const handleClick = () => {
    return props.handelClose();
  };

  const onError = (e: any) => {
    console.log("Error", e);
  };

  const onSuccess1 = (res: any) => {
    console.log(res.url);
    setCardUploadedLogos((cardUploadedLogos) => [
      res.thumbnailUrl,
      ...cardUploadedLogos,
    ]);
    console.log(res);
    setCardUploadId(res.fileId);
    setCertificateUrl(res.url);
  };

  const onSuccess2 = (res: any) => {
    console.log(res.url);
    setCardUploadedLogos((cardUploadedLogos) => [
      res.thumbnailUrl,
      ...cardUploadedLogos,
    ]);
    console.log(res);
    setCardUploadId(res.fileId);
    setReportCardUrl(res.url);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    
    getFirstdata();
    //  console.log(List);
    
  }, []);

  const getFirstdata = () => {
    getCourseFillDetails()
      .then((data) => {
        setList(data);
        setCategory(data[0]._id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setData = (e: any) => {
    const choosen = List.filter((m: any) => m.name == e.target.value);
    setCategory(choosen[0]._id);
    setMyList(choosen);
    if (myList) {
      setShow(true);
    }
  };

  const showData = async () => {
    if (!courseName) {
      return INFO_TOAST("please enter a course name !");
    }

    if (!category) {
      return INFO_TOAST("please choose a Category !");
    }

    const data = {
      name: courseName,
      active: status,
      assessment: Assesments,
      certification_levels: certificateLevel,
      assessment_levels: AssesmentLevel,
      category: category,
      report_card: false,
      certificate: false,
      course_completion: courseCompletion,
    };
    const result = await createCourse(data);
    if (result.message == "Course Created Successfully") {
      SUCCESS_TOAST("Course Created Successfully");
      getFirstdata();
      setTimeout(() => {
        handleClick();
      }, 2000);
    } else {
      ERROR_TOAST("something went wrong, Try again !");
    }
  };

  const updateData = async () => {
    const data = {
      _id: props.data?._id,
      name: courseName !== props.data?.name ? courseName : props.data?.name,
      active: status !== props.data?.active ? status : props.data?.active,
      assessment:
        Assesments !== props.data?.assessment
          ? Assesments
          : props.data?.assessment,
      certification_levels:
        certificateLevel !== props.data?.certification_levels.length
          ? certificateLevel
          : props.data?.certification_levels.length,
      assessment_levels:
        AssesmentLevel !== props.data?.assessment_levels.length
          ? AssesmentLevel
          : props.data?.assessment_levels.length,
      // category: category,
      report_card: false,
      course_completion:
        courseCompletion !== props.data?.course_completion
          ? courseCompletion
          : props.data?.course_completion,
    };

    const result = await updateCourse(data);
    if (!result) {
      return ERROR_TOAST("Something Went Wrong !");
    }

    SUCCESS_TOAST("Course Updated Successfully !");
    getFirstdata();
    handleClick();
  };

  return (
    <>
      <div className="addCourse">
        <div className="fullDesc">
          <div className="headerAC">
            <h2>{props.data ? "Update Course" : "Add Course"}</h2>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="ac-border-top"></div>
          <div className="ad-body">
            <div className="previous-drop">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Choose Category*
                </label>
                <select
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="Category"
                  disabled={props.data ? true : false}
                  onChange={setData}
                >
                  {List.map((m: any) => (
                    <>
                      <option
                        key={m._id}
                        selected={m._id == props.data?.category ? m.name : ""}
                      >
                        {m.name}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cname">
                  Course Name*
                </label>
                <input
                  type="text"
                  name="course_name"
                  value={courseName}
                  className="setSecond form-control dropdown-toggle"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
            <div className="next-drop">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Certification Levels*
                </label>
                <input
                  type="text"
                  className="form-select setThird"
                  disabled={props.data ? true : false}
                  aria-label="Default select example"
                  name="Certification Levels"
                  defaultValue={certificateLevel}
                  onChange={(e) => setCertificateLevel(e.target.value)}
                />
              </div>
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Assessment Levels*
                </label>
                <input
                  type="text"
                  defaultValue={AssesmentLevel}
                  className="form-select setThird"
                  disabled={props.data ? true : false}
                  aria-label="Default select example"
                  name="Assessment Levels"
                  onChange={(e) => setAssesmentLevel(e.target.value)}
                />
              </div>
              {/* radios */}
              <div className="radios ">
                <div>
                  <label className="cat-hello mx-1" htmlFor="cat">
                    Assignement
                  </label>
                  <div className="radio-child ">
                    <label className="align-label px-2">
                      <input
                        type="radio"
                        name="choice-radio"
                        checked={Assesments}
                        onClick={() => setAssesments(true)}
                      />
                      <span className="mx-2"> Yes</span>
                    </label>
                    <label className="align-label px-4">
                      <input
                        type="radio"
                        name="choice-radio"
                        checked={!Assesments}
                        onClick={() => setAssesments(false)}
                      />
                      <span className="mx-2"> No</span>
                    </label>
                  </div>
                </div>
                {/* radio 2 */}
                <div className="px-3">
                  <label className="cat-hello mx-1" htmlFor="cat">
                    Course Completion
                  </label>
                  <div className="radio-child ">
                    <label className="align-label px-2">
                      <input
                        type="radio"
                        name="choice-radio2"
                        checked={courseCompletion}
                        onClick={() => setcourseCompletion(true)}
                      />
                      <span className="mx-2"> Yes</span>
                    </label>
                    <label className="align-label px-4">
                      <input
                        type="radio"
                        name="choice-radio2"
                        checked={!courseCompletion}
                        onClick={() => setcourseCompletion(false)}
                      />
                      <span className="mx-2"> No</span>
                    </label>
                  </div>
                </div>
                {/* radio 3 */}
                <div className="mx-3">
                  <label className="cat-hello mx-1" htmlFor="cat">
                    Status
                  </label>
                  <div className="radio-child ">
                    <label className="align-label px-2">
                      <input
                        type="radio"
                        name="choice3"
                        checked={status}
                        onClick={() => setStatus(true)}
                      />
                      <span className="mx-2"> Active</span>
                    </label>
                    <label className="align-label px-4">
                      <input
                        type="radio"
                        name="choice3"
                        checked={!status}
                        onClick={() => setStatus(false)}
                      />

                      <span className="mx-2"> Inactive</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="drag-drop-top-line"></div>
          <div className="upload-drag-drop d-flex justify-content-around">
            <div className="">
              <label htmlFor="report-card">Upload Report Card</label>
              {/* 
              <FileUploader
                // handleChange={handleChange}
                name="file"
                // types={fileTypes}
              /> */}
              <IKContext
                publicKey="public_j5S33KH3AQ/NdlZ3jumN7sZ6AmY="
                urlEndpoint="https://ik.imagekit.io/vecch6x6p"
                authenticationEndpoint="http://localhost:5500/API/V1/card/auth"
              >
                <div className="upload-area">
                  <IKUpload
                    fileName="my-upload"
                    onError={onError}
                    onSuccess={onSuccess2}
                  />
                  <div className="upload-icon">
                    <Upload />
                  </div>
                  <div className="upload-text">
                    <p>Upload or drag & drop files here</p>
                    <p id="upload-text-second">JPG, PNG upto 10mb</p>
                  </div>
                </div>
              </IKContext>
            </div>
            <div className="">
              <label htmlFor="certificate">Upload Certificate</label>
              {/* <FileUploader
                // handleChange={handleChange}
                name="file"
                // types={fileTypes}
              /> */}
              <IKContext
                publicKey="public_j5S33KH3AQ/NdlZ3jumN7sZ6AmY="
                urlEndpoint="https://ik.imagekit.io/vecch6x6p"
                authenticationEndpoint="http://localhost:5500/API/V1/card/auth"
              >
                <div className="upload-area">
                  <IKUpload
                    fileName="my-upload"
                    onError={onError}
                    onSuccess={onSuccess1}
                  />
                  <div className="upload-icon">
                    <Upload />
                  </div>
                  <div className="upload-text">
                    <p>Upload or drag & drop files here</p>
                    <p id="upload-text-second">JPG, PNG upto 10mb</p>
                  </div>
                </div>
              </IKContext>
            </div>
          </div>
          <div className="drag-drop-top-line"></div>
          <div className="down-buttons d-flex">
            <button
              className="create-button"
              onClick={() => {
                props.data ? updateData() : showData();
              }}
            >
              {props.data ? "Update Course" : "Create New Course"}
            </button>
            <button className="cancel-button" onClick={() => handleClick()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
