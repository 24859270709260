import React from "react";
import { Toaster } from "react-hot-toast";
import { Formik, Form, Field } from 'formik';
import { IKContext, IKUpload } from 'imagekitio-react';
import { createEvent, updateEvent } from "../../API/events";
import "./addChallenge.css";
// import "./bootstrap.css"

const urlEndpoint = 'https://ik.imagekit.io/um0aq1lu5';
const publicKey = 'public_2QLPd9YnwQMW6ylSe6S+J+oYIdw=';
const authenticationEndpoint = 'https://suprkid-backend-dev.herokuapp.com/api/v1/event/auth';

interface AddEventProps {
    handleClose: () => void;
    data?: {
        _id: string,
        title: string,
        startDate: string,
        endDate: string,
        days: number
        minDuration: number,
        maxDuration: number,
        minAge: number,
        maxAge: number,
        mode: string,
        overview: string

        isActive: boolean,
        imageUrl: string;
        outcome: [string],
        demoUrl: string,
    } | undefined;
    changeIsActive: boolean;
}

interface FormValuesType {
    title: string,
    startDate: string,
    endDate: string,
    days: number,
    minDuration: number,
    maxDuration: number,
    minAge: number,
    maxAge: number,
    mode: string,
    overview: string,
    imageUrl: null | Object

}

const AddEvent: React.FC<AddEventProps> = (props) => {

    const onError = (error: any) => {
        console.log("Error", error)
    }

    const handleSubmit = async (values: FormValuesType) => {
        try {
            const newEvent = JSON.stringify({
                title: values.title,
                description: values.overview,
                startDate: values.startDate,
                endDate: values.endDate,
                days: values.days,
                startDateTime: `${values.startDate} 12:30am`,
                minDuration: values.minDuration,
                maxDuration: values.maxDuration,
                minAge: values.minAge,
                maxAge: values.maxAge,
                mode: values.mode,
                overview: values.overview,

                isActive: props.changeIsActive ? !props.data?.isActive : false,
                imageUrl: values.imageUrl,
                outcome: ["lorem ipsum dori", "lorem ipsum dori", "lorem ipsum dori"],
                demoUrl: "nan",
            })
            if (props.data) {
                await updateEvent(newEvent)
                alert(`Event Successfully ${props.changeIsActive ? `${props.data.isActive ? "Dea" : "A"}ctivated` : "Updated"}`)
            }
            else {
                await createEvent(newEvent)
                alert("Event Successfully Created")
            }
        }
        catch (e) {
            console.log("*******Error*******", e)
        }
        props.handleClose()

    }

    let headerTitle;
    if (props.changeIsActive && props.data) {
        headerTitle = "Activate Event"
    }
    else if (!props.changeIsActive && props.data) {
        headerTitle = "Update Event"
    }
    else {
        headerTitle = "Add New Event"
    }

    return (
        <>
            <Toaster
                toastOptions={{
                    style: {
                        color: "black",
                    },
                }}
            />
            <div className="addChallenge">
                <div className="fullDesc">
                    <div className="header">
                        <h2>{headerTitle}</h2>
                        <div
                            className="top-svg"
                            onClick={() => props.handleClose()}
                            style={{ cursor: "pointer" }}
                        >
                            <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                                    fill="#8E8EA1"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="ad-body">
                        <Formik
                            initialValues={
                                {
                                    title: props.data?.title || "Abacus",
                                    startDate: props.data?.startDate ? props.data.startDate : "2022-01-01",
                                    endDate: props.data?.endDate ? props.data.endDate : "2022-01-01",
                                    days: props.data?.days || 0,
                                    minDuration: props.data?.minDuration || 1,
                                    maxDuration: props.data?.maxDuration || 10,
                                    minAge: props.data?.minAge || 1,
                                    maxAge: props.data?.maxAge || 3,
                                    mode: props.data?.mode || "Online",
                                    overview: props.data?.overview || "",
                                    imageUrl: props.data?.imageUrl || null,
                                }}

                            onSubmit={(values, { setSubmitting }) => {
                                handleSubmit(values)
                            }}
                        >
                            {({ values, setFieldValue, handleChange }) => (
                                <Form>
                                    <div className="inputsRow">
                                        <div className="input titleInput">
                                            <label htmlFor="title" className="label">Event Type</label>
                                            <select id="title" name="title" className="inputField form-select" placeholder="Event Type">
                                                <option value="Mental Math">Mental Math</option>
                                                <option value="Abacus">Abacus</option>
                                                <option value="Quiz">Quiz</option>
                                            </select>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="startDate" className="label">Start Date</label>
                                            <Field type="date" id="startDate" name="startDate" placeholder="Start Date" className="inputField" />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="endDate" className="label">End Date</label>
                                            <Field type="date" id="endDate" name="endDate" className="inputField" placeholder="End Date" />
                                        </div>
                                    </div>
                                    <div className="inputsRow">
                                        <div className="input">
                                            <label htmlFor="days" className="label">Days</label>
                                            <Field type="number" id="days" name="days" placeholder="Days" className="inputField" />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="minDuration" className="label">Duration Limit</label>
                                            <div className="ageGroupInput">
                                                <input
                                                    type="number"
                                                    id="minDuration"
                                                    name="minDuration"
                                                    className="inputField smallInputField"
                                                    placeholder="Min"
                                                    value={values.minDuration}
                                                    onChange={handleChange}
                                                />

                                                <input
                                                    type="number"
                                                    id="maxDuration"
                                                    name="maxDuration"
                                                    className="inputField smallInputField"
                                                    placeholder="Max "
                                                    value={values.maxDuration}
                                                    onChange={handleChange}
                                                />


                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="minAge" className="label">Age Limit</label>
                                            <div className="ageGroupInput">
                                                <input
                                                    type="number"
                                                    id="minAge"
                                                    name="minAge"
                                                    className="inputField smallInputField"
                                                    placeholder="Min"
                                                    value={values.minAge}
                                                    onChange={handleChange}
                                                />
                                                <input
                                                    type="number"
                                                    id="maxAge"
                                                    name="maxAge"
                                                    className="inputField smallInputField"
                                                    placeholder="Max"
                                                    value={values.maxAge}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="mode" className="label">Mode</label>
                                            <select
                                                id="mode"
                                                name="mode"
                                                placeholder="Mode"
                                                className="inputField form-select"
                                                value={values.mode}
                                                onChange={handleChange}
                                            >
                                                <option value="Online">Online</option>
                                                <option value="Offline">Offline</option>
                                                <option value="Hybrid">Hybrid</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="inputsRow">
                                        <div className="input">
                                            <label htmlFor="overview" className="label">Overview</label>
                                            <Field type="text" name="overview" placeholder="Overview" className="inputField overview" />
                                        </div>
                                    </div>
                                    <div className="inputsRow">
                                        <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticationEndpoint={authenticationEndpoint} >
                                            <IKUpload
                                                fileName={`${values.title}`}
                                                onError={onError}
                                                onSuccess={(res: any) => setFieldValue("imageUrl", res.url, false)}
                                                className={"uploadButton"}
                                            />
                                            {/* <FileUploader
                                                handleChange={handleChange}
                                                className="fileUploadInput"
                                                name="imageUrl"
                                                types={fileTypes}
                                                label="Upload imageUrl Image"
                                                maxSize="5"
                                                minSize="0"
                                                onSizeError={() => console.log("Invalid Size")}
                                            /> */}
                                        </IKContext>

                                    </div>
                                    <div className="footer">
                                        {props.changeIsActive ?
                                            <button typeof="submit" className="btn submit repair">
                                                {props.data?.isActive ? "Deactivate Event" : "Activate Event"}
                                            </button> :
                                            <button typeof="submit" className="btn submit repair">
                                                {props.data ? "Update" : "Submit"}
                                            </button>
                                        }

                                        <button className="btn cancel repair" onClick={() => props.handleClose()}>
                                            Cancel
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}


export { AddEvent } 