import { call } from "../helpers/axiosInterceptor";

const getAllStudentProfiles = async () => {
  try {
    const { data } = await call.get("user",
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);

    return data;
  } catch (e) {
    console.log(e);
  }
};

const createAssessment = async (payload: any) => {
  try {
    const { data } = await call.post("assessment", {
      ...payload,
    },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export { getAllStudentProfiles, createAssessment };
