import React, { useState } from "react";
import { DataGrid, GridToolbar, GridRenderCellParams } from "@mui/x-data-grid";


export default function MainTable({ rows, columns, loading }) {
  const [pageSize, setPageSize] = useState(5);
  return (
    <>
      <div style={{ height: 420, width: "100%", background: "white" }}>
        {/* <MaxWidthDialog refresh={fetchStudents} /> */}
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(rows) => Math.random()}
          rowHeight={55}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          rowSpacingType="border"
          sx={{
            borderRadius: "15px",
            background: "#FFFFFF",
            boxShadow: "0px 0px 10px rgba(116, 128, 135, 0.07);",
            "& 	.MuiDataGrid-columnHeaders": {
              color: "#65708D",
              fontSize: "11px",
            },
            "& .MuiDataGridPro-row ": {
              color: "red",
            },
            "& .MuiDataGrid-row": {
              "&:hover, &.Mui-hovered": {
                background: "#F2FBFF",
              },
            },
          }}
          
        />
      </div>
    </>
  );
}
