import * as React from "react";
import ManageLiveTiles from "./manageLiveTiles";

export default function ContentMgmt() {


  return (
    <>
    <div className="header-contentMgmt">
    <ManageLiveTiles />
    </div>
    </>
  );
}
