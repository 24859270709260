import { call } from "../helpers/axiosInterceptor";

const getAllStudents = async () => {
  const { data } = await call.get("user",
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};

const createStudent = async (payload: any) => {
  const { data } = await call.post("user/create/admin", { ...payload },
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);

  return data;
};

const updateKid = async (payload: any) => {
  const { data } = await call.post("user/update", { ...payload },
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};

const getKidsBysearch = async (query) => {
  const { data } = await call.get(`user/search/${query}`,
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};
const deleteKid = async (id) => {
  const { data } = await call.delete(`user/delete/${id}`,
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};


export { getAllStudents, createStudent, updateKid, getKidsBysearch ,deleteKid};
