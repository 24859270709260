import * as  React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddBatch from '../../components/buttons/AddBatch';

const columns = [];

function BatchSessions() {
  const [rows, setRows] = React.useState([]);
  return (
    <>
    <TableContainer component={Paper} className="p-3">
        <div className="d-flex justify-content-between">
        <h2 className='m-2 header-downTables-dashboard' >Batch Allocation</h2>
        <AddBatch />
        </div>
      <Table aria-label="simple table" >
        <TableHead>
          <TableRow className='table-head-dashboard'>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((m: any) => (
            <TableRow
              hover={true}
              key={m._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {m.course_id}
              </TableCell>
              <TableCell align="left">{m.name}</TableCell>
              <TableCell align="left">{m.category}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></>
  )
}

export default BatchSessions