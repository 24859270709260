import { call } from "../helpers/axiosInterceptor";

const getAllPoints = async () => {
  try {
    const { data } = await call.get("card/list",
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const createPoints = async (payload: any) => {
  try {
    const { data } = await call.post("card/", { ...payload },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const updatePoints = async (payload: any) => {
  try {
    const { data } = await call.post("card/update", { ...payload },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const allocateCard = async (payload: any) => {
  try {
    const { data } = await call.post("userPoints", { ...payload },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export { getAllPoints, createPoints, updatePoints, allocateCard };
