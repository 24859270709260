import { call } from "../helpers/axiosInterceptor";

const getCourseFillDetails = async () => {
  try {
    const { data } = await call.get("interests",
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);

    return data;
  } catch (e) {
    console.log(e);
  }
};

const createCourse = async (payload: any) => {
  try {
    const { data } = await call.post("course/create", { ...payload },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);

    return data;
  } catch (e) {
    console.log(e);
  }
};

const getCourses = async () => {
  try {
    const { data } = await call.get("course/list",
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const updateCourse = async (payload: any) => {
  try {
    const { data } = await call.post("course/update", { ...payload },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getCoursesById = async (id: any) => {
  try {
    const { data } = await call.get(`course/list/${id}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const getCoursesBysearch = async (query: any) => {
  try {
    const { data } = await call.get(`course/search/${query}`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export {
  getCourseFillDetails,
  createCourse,
  getCourses,
  getCoursesById,
  updateCourse,
  getCoursesBysearch,
};
