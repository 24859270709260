import React, { useState } from "react";
import AddnewCard from "../addNewCard/addNewCard";
import AddCourse from "../addNewCourse/addCourse";
import CreateBatch from "../createNewBatch/createBatch";
import "./addCard.css";
interface AddButtonProp {
  value?: string;
}

export default function AddCard(props: AddButtonProp) {
  const [addCourse, setCourse] = useState(false);

  const handelClose = () => {
    setCourse(false);
  };

  return (
    <>
      <button className="addBtnCard-card" onClick={() => setCourse(!addCourse)}>
        <div className="btn-inside-Card">
          <span>Create New Card</span>
          <span>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 5.25H6.75V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790178 6.19891 0 6 0C5.80109 0 5.61032 0.0790178 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V5.25H0.75C0.551088 5.25 0.360322 5.32902 0.21967 5.46967C0.0790178 5.61032 0 5.80109 0 6C0 6.19891 0.0790178 6.38968 0.21967 6.53033C0.360322 6.67098 0.551088 6.75 0.75 6.75H5.25V11.25C5.25 11.4489 5.32902 11.6397 5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803C6.67098 11.6397 6.75 11.4489 6.75 11.25V6.75H11.25C11.4489 6.75 11.6397 6.67098 11.7803 6.53033C11.921 6.38968 12 6.19891 12 6C12 5.80109 11.921 5.61032 11.7803 5.46967C11.6397 5.32902 11.4489 5.25 11.25 5.25Z"
                fill="#EB1556"
              />
            </svg>
          </span>
        </div>
      </button>

      {addCourse && <AddnewCard handelClose={handelClose} />}
    </>
  );
}
