import React, { useEffect } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import Dashboard from "../pages/Dashboard/dashboard";
import Login from "../pages/Auth/login";
import Batches from "../pages/Batches/batches";
import CoachMgmt from "../pages/coach-mgmt/coach-mgmt";
import courses from "../pages/courses/courses";
import KidsMgmt from "../pages/kids-mgmt/kids-mgmt";
import pointsSetUp from "../pages/points/points-set-up";
import Notifications from "../pages/notifications/notifications";
import AddCourse from "./addNewCourse/addCourse";
import EventsAndChallenges from "../pages/events-and-challenges/EventsAndChallenges";
import ContentMgmt from "../pages/content-mgmt/content-management";
import { RootStateOrAny, useSelector } from "react-redux";
import StudMgmt from "../pages/student-mgmt/StudentManagement";

const Routes = () => {
  const user = useSelector((state: RootStateOrAny) => state.auth);

  let routes = <Route path="/login" component={Login} />;

  if (user?.isSuper) {
    routes = (
      <>
        <Route path="/" exact component={Dashboard} />
        <Route path="/batch" component={Batches} />
        <Route path="/course-set-up" component={courses} />
        <Route path="/event-and-challenge" component={EventsAndChallenges} />
        <Route path="/points-set-up" component={pointsSetUp} />
        <Route path="/coach-management" component={CoachMgmt} />
        <Route path="/kids-mgmt" component={KidsMgmt} />
        <Route path="/student-mgmt" component={StudMgmt} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/content-mgmt" component={ContentMgmt} />
      </>
    );
  }

  if (!user?.isSuper) {
    routes = (
      <>
        <Route path="/" exact component={CoachMgmt} />
        <Route path="/kids-mgmt" component={KidsMgmt} />
        <Route path="/student-mgmt" component={StudMgmt} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/content-mgmt" component={ContentMgmt} />
      </>
    );
  }

  if (user?.isCoach) {
    routes = (
      <>
        <Route exact path="/">
          <Redirect to="/student-mgmt" />
        </Route>
        <Route path="/student-mgmt" exact component={StudMgmt} />
        <Route path="/notifications" component={Notifications} />
      </>
    );
  }

  return <Switch>{routes}</Switch>;
};

export default Routes;
