import React, { useEffect, useState } from "react";
import "./App.css";

// import Sidebar from '../sidebar/Sidebar'
// import TopNav from '../topnav/TopNav'
// import Routes from '../Routes'

import { BrowserRouter, Route } from "react-router-dom";
import Routes from "./components/routes";
import SideBar from "./components/SideBar/SideBar";
import Login from "./pages/Auth/login";
import UserData from "./components/userLoged/userData";
import NotificationBell from "./components/notification/notification";
import { useSelector } from "react-redux";
import { DefaultReduxState } from "./store";
const App = () => {
  //   const [isAuthorized, setisAuthorized] = useState<boolean>(false)
  const [isSidebarActive, setisSidebarActive] = useState<boolean>(false);

  const { auth } = useSelector((state: DefaultReduxState) => state);
  // const isUser = () => {
  //     setisAuthorized(true);
  // }

  const isActive = () => {
    setisSidebarActive(!isSidebarActive);
  };

  return (
    <BrowserRouter>
      {!auth?._id ? (
        <Login />
      ) : (
        <Route
          render={(props: any) => (
            <div className={`layout`}>
              <SideBar {...props} isActive={isActive} />
              <div className="loggedUser">
                <NotificationBell />
                <UserData />
              </div>
              <div
                className={`layout__content  ${
                  isSidebarActive ? "fullLayout" : ""
                }`}
              >
                <div className="layout__content-main">
                  <Routes />
                </div>
              </div>
            </div>
          )}
        />
      )}
    </BrowserRouter>
  );
};

export default App;
