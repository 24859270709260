import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { getCourseFillDetails, getCourses } from "../../API/courses";

const columns = [];

export default function ManageLiveTiles() {
  const [rows, setRows] = React.useState([]);

  //   React.useEffect(() => {
  //     getCourses()
  //       .then((data) => {
  //         console.log(data);
  //         setRows(data.slice(0, 3));
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }, []);

  return (
    <TableContainer component={Paper} className="p-3" >
      <h2 className="m-2  header-downTables-dashboard">Content Managment</h2>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table-head-dashboard'>
            <TableCell>ID </TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Video</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((m: any) => (
            <TableRow
              hover={true}
              key={m._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {m.course_id}
              </TableCell>
              <TableCell align="left">{m.name}</TableCell>
              <TableCell align="left">{m.category}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
