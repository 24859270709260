import React, { useEffect, useState } from "react";
import { getCoursesBysearch } from "../../API/courses";
import AddButton from "../../components/buttons/addButton";
import { ERROR_TOAST } from "../../helpers/toasters";
import "./courses.css";
import ActiveCoursesList from "./CoursesList";

export default function Courses() {
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="courseSetUp">
        <div className="topList">
          <h2 className="top-heading">Active Courses</h2>
          <div className="search-inp">
            <button type="submit">search</button>
            <input
              className="dashboard-search"
              type="search"
              value={search}
              placeholder="Search course..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <AddButton value="Add New Course" />
        </div>
        <ActiveCoursesList search={search} />
      </div>
    </>
  );
}
