import { call } from "../helpers/axiosInterceptor";

const getEvents = async () => {
  try {
    const { data } = await call.get(`event/read`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const createEvent = async (data: any) => {
  try {
    const res = await call.post("event/", { data },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const updateEvent = async (data: any) => {
  try {
    const res = await call.put("event/", { data },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

export { getEvents, createEvent, updateEvent };
