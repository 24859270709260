import React from "react";

import AddCard from "../../components/buttons/AddCard";
import PointsList from "./pointsList";

export default function () {
  return (
    <>
      <div className="courseSetUp">
        <div className="topList">
          <h2 className="top-heading">Add New Card</h2>
          <div className="search-inp">
            <button type="submit">search</button>
            <input
              className="dashboard-search"
              type="search"
              placeholder="Search Card..."
            />
          </div>
          <AddCard />
        </div>
        <PointsList />
      </div>
    </>
  );
}
