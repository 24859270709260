import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getCourses } from "../../API/courses";
import { Chip } from "@mui/material";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { red } from "@mui/material/colors";
import { ERROR_TOAST } from "../../helpers/toasters";

export default function BasicTable() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    getCourses()
      .then((data) => {
        // if (!data) {
        //   ERROR_TOAST("Error Connecting to Server !");
        //   setRows([]);
        // }
        console.log(data);
        setRows(data.slice(0, 3));
        console.log(data.slice(0, 3));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="table-head-dashboard">
            <TableCell>ID </TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Assessment</TableCell>
            <TableCell align="left">Ass Levels</TableCell>

            <TableCell align="left">Cert Level</TableCell>

            <TableCell align="left">Report Card </TableCell>

            <TableCell align="left">Certificate</TableCell>
            <TableCell align="left">Completion</TableCell>

            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((m: any) => (
            <TableRow
              className="ActiveCourseData"
              key={m._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.course_id}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.name}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.category.name}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.assessment ? "Yes" : "No"}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.assessment_levels.length}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.certification_levels.length}
              </TableCell>
              <TableCell align="left">
                <Chip
                  label={m.report_card == true ? "Sent" : "Pending"}
                  icon={
                    m.report_card == true ? (
                      <CheckCircleOutlinedIcon
                        style={{ color: "#fff", fontSize: "20px" }}
                      />
                    ) : (
                      <ReportOutlinedIcon
                        style={{ color: "#fff", fontSize: "20px" }}
                      />
                    )
                  }
                  style={{
                    background: m.report_card == true ? "#7A9BF9" : "#FFB74A",
                    fontFamily: "poppins",
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "11px",
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <Chip
                  label={m.certificate == true ? "Sent" : "Pending"}
                  icon={
                    m.certificate == true ? (
                      <CheckCircleOutlinedIcon
                        style={{ color: "#fff", fontSize: "20px" }}
                      />
                    ) : (
                      <ReportOutlinedIcon
                        style={{ color: "#fff", fontSize: "20px" }}
                      />
                    )
                  }
                  style={{
                    background: m.certificate == true ? "#7A9BF9" : "#FFB74A",
                    fontFamily: "poppins",
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "11px",
                  }}
                />
              </TableCell>
              <TableCell
                align="left"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                {m.course_completion ? "Yes" : "No"}
              </TableCell>
              <TableCell align="left">
                <Chip
                  label={m.active ? "Active" : "Inactive"}
                  style={{
                    background: m.active ? "#5ED7C3" : red[700],
                    fontFamily: "poppins",
                    width: 70,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "11px",
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
