import React, { useEffect, useState } from "react";
import { getAllPoints } from "../../API/points";
import {
  createAssessment,
  getAllStudentProfiles,
} from "../../API/student-mgmt";
import Upload from "../../icons/upload";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
import "./manageAssesment.css";
import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import { getCoursesById } from "../../API/courses";
interface handelFuncProps {
  handelClose: () => void;
  data?: {
    studentname: string;
    assessment_levels: string[];
    category: string;
    certification_levels: string[];
    course_completion: boolean;
    report_card: string;

    _id: string;
  };
}

const fileTypes = ["JPG", "PNG", "GIF"];
const uri =
  process.env.NODE_ENV == "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_URL;
export default function ManageAssisment(props: handelFuncProps) {
  const [status, setStatus] = useState<boolean>(
    props.data?.course_completion || false
  );
  const [student, setStudent] = useState("");
  const [course, setCourse] = useState("");
  const [studentRows, setStudentRows] = useState([]);
  const [cardRows, setCardRows] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState("");
  const [reportCardUrl, setReportCardUrl] = useState("");
  const [certificateId, setCertificateId] = useState("");
  const [reportCardId, setReportCardId] = useState("");
  const [assessment, setAssessment] = useState<any>();
  const [certification, setCertification] = useState<any>();
  const [selectedAssessment, setSelectedAssessment] = useState<any>("");
  const [selectedCertification, setSelectedCertification] = useState<any>("");

  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    return props.handelClose();
  };
  const onError = (e: any) => {
    ERROR_TOAST("Error while uploading your documents");
    console.log("Error", e);
  };
  const onSuccess1 = (res: any) => {
    SUCCESS_TOAST("Certificate Uploaded !");
    setCertificateId(res.fileId);
    setCertificateUrl(res.url);
  };
  const onSuccess2 = (res: any) => {
    SUCCESS_TOAST("Report Card Uploaded !");
    setReportCardId(res.fileId);
    setReportCardUrl(res.url);
  };
  useEffect(() => {
    getFirstData();
  }, []);

  useEffect(() => {
    getCoursesById(course)
      .then((data) => {
        setAssessment(data[0]["assessment_levels"]);
        setCertification(data[0]["certification_levels"]);
        setSelectedAssessment(data[0]["assessment_levels"][0]);
        setSelectedCertification(data[0]["certification_levels"][0]);
      })
      .catch((e) => ERROR_TOAST("Error while getting course levels"));
  }, [course]);

  const getFirstData = () => {
    getAllStudentProfiles()
      .then((data) => {
        if (!data) {
          ERROR_TOAST("Error while getting students profiles");
          setStudentRows([]);
        }

        setStudentRows(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });

    // getAllPoints()
    //   .then((data) => {
    //     if (!data) {
    //       ERROR_TOAST("No data found for cards");
    //       setCardRows([]);
    //       setLoading(false);
    //     }
    //     setCardRows(data);
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     ERROR_TOAST("error Connecting to Server !");
    //     setLoading(false);
    //   });
  };

  const onSubmit = async () => {
    setLoading(true);

    if (!student) {
      return INFO_TOAST("Please choose a student");
    }

    if (!course) {
      return INFO_TOAST("Please choose a course for student");
    }

    if (!selectedAssessment) {
      return INFO_TOAST("Please choose a Assessment level");
    }

    if (!selectedCertification) {
      return INFO_TOAST("Please choose a Certificate level");
    }

    const data = {
      course,
      user: student,
      certificate: certificateUrl,
      reportCard: reportCardUrl,
      assessment_level: selectedAssessment,
      certificate_level: selectedCertification,
    };

    const result = await createAssessment(data);
    if (result.response.statusCode === 200) {
      setLoading(false);
      SUCCESS_TOAST("Assesment Added Successfully");
    } else {
      setLoading(false);
      return ERROR_TOAST("something went wrong, try again!");
    }

    handleClick();
  };

  return (
    <div className="manageA">
      <div className="fullDescManageA">
        <div className="headerofma">
          <h2>Manage Assesment</h2>
          <div
            className="top-svg"
            onClick={() => handleClick()}
            style={{ cursor: "pointer" }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                fill="#8E8EA1"
              />
            </svg>
          </div>
        </div>
        <div className="MAssisment-border-top"></div>
        <div className="ma-body">
          <div className="first-dropMa">
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Student Name
              </label>
              <select
                name="Student_name"
                aria-label="Default select example"
                className="form-select setFirst"
                onChange={(e) => setStudent(e.target.value)}
              >
                <option>Select a student</option>;
                {studentRows.map((m: any) => (
                  <option key={m._id} value={m._id}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="next-valuema">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Course Catagory
                </label>
                <select
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="Allocate Card"
                  onChange={(e) => setCourse(e.target.value)}
                >
                  <option>Choose a course</option>
                  {studentRows.map((z: any) => (
                    <>
                      {z.courses.map((m: any) => (
                        <option key={m._id} value={m._id}>
                          {m.name}
                        </option>
                      ))}
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="next-dropMa">
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Certification Levels
              </label>
              <select
                className="form-select setThird"
                aria-label="Default select example"
                name="Certification Levels"
                onChange={(e) => setSelectedCertification(e.target.value)}
              >
                {certification && (
                  <>
                    {certification.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="dropdown">
              <label className="cat-hello" htmlFor="cat">
                Assessment Levels
              </label>

              <select
                className="form-select setThird"
                aria-label="Default select example"
                name="Assessment Levels"
                onChange={(e) => setSelectedAssessment(e.target.value)}
              >
                {assessment && (
                  <>
                    {assessment.map((m, i) => (
                      <option key={i} value={m}>
                        {m}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="px-3">
              <label className="cat-hello mx-1" htmlFor="cat">
                Course Completion
              </label>
              <div className="radio-child ">
                <label className="align-label px-2">
                  <input
                    type="radio"
                    name="choice-radio2"
                    checked={status}
                    onClick={() => setStatus(true)}
                  />
                  <span className="mx-2"> Yes</span>
                </label>
                <label className="align-label px-4">
                  <input
                    type="radio"
                    name="choice-radio2"
                    checked={!status}
                    onClick={() => setStatus(false)}
                  />
                  <span className="mx-2"> No</span>
                </label>
              </div>
            </div>
          </div>
          <div className="upload-drag-drop d-flex justify-content-around">
            <div className="">
              <label htmlFor="report-card">Upload Report Card</label>
              <IKContext
                publicKey="public_j5S33KH3AQ/NdlZ3jumN7sZ6AmY="
                urlEndpoint="https://ik.imagekit.io/vecch6x6p"
                authenticationEndpoint={uri + `card/auth`}
              >
                <div className="upload-area">
                  <div className="upload-icon">
                    <Upload />
                  </div>
                  <div className="upload-text">
                    <p>Upload or drag & drop files here</p>
                    <p id="upload-text-second">PNG upto 10mb</p>
                  </div>
                  <IKUpload
                    fileName="my-upload"
                    onError={onError}
                    onSuccess={onSuccess2}
                  />
                </div>
              </IKContext>
            </div>
            <div className="">
              <label htmlFor="certificate">Upload Certificate</label>
              <IKContext
                publicKey="public_j5S33KH3AQ/NdlZ3jumN7sZ6AmY="
                urlEndpoint="https://ik.imagekit.io/vecch6x6p"
                authenticationEndpoint={uri + `card/auth`}
              >
                <div className="upload-area">
                  <div className="upload-icon">
                    <Upload />
                  </div>

                  <div className="upload-text">
                    <p>Upload or drag & drop files here</p>
                    <p id="upload-text-second">PNG upto 10mb</p>
                  </div>
                  <IKUpload
                    fileName="my-upload"
                    onError={onError}
                    onSuccess={onSuccess1}
                  />
                </div>
              </IKContext>
            </div>
          </div>
          <div className="drag-drop-top-lineMa"></div>
          <div className="but-ManageA d-flex">
            <button className="submit-button-Ma" onClick={() => onSubmit()}>
              Submit
            </button>
            <button
              className="manageA-cancel-button"
              onClick={() => handleClick()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
