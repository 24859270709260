import { combineReducers, Reducer } from 'redux'
import {persistReducer} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from './storage'

import auth from './auth'

const persistConfig = {
    key: 'root',
    stateReconciler: autoMergeLevel2,
    storage
}

const rootReducer:Reducer<any,any> = combineReducers({auth})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer