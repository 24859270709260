const x = document.querySelector('.upload-area');

// const z = document.getElementsByTagName('input');


// x?.addEventListener('click', ()=> {
//     console.log(z.item  );
    
// })

export{}