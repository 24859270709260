export const SET_AUTH = "SET_AUTH";
export const DEL_AUTH = "DEL_AUTH";
export const GET_AUTH = "GET_AUTH";

export const setUser = (user: object) => ({
  type: SET_AUTH,
  payload: { ...user },
});
export const delUser = () => ({ type: DEL_AUTH });
export async function getUser(getState) {
  const state = getState();
  console.log(state);
}
