import React from 'react'

 function ContentActiveSVG() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6667 2.5H3.33341C2.41425 2.5 1.66675 3.2475 1.66675 4.16667V15.8333C1.66675 16.7525 2.41425 17.5 3.33341 17.5H16.6667C17.5859 17.5 18.3334 16.7525 18.3334 15.8333V4.16667C18.3334 3.2475 17.5859 2.5 16.6667 2.5ZM3.33341 15.8333V4.16667H9.16675V15.8333H3.33341ZM10.8334 15.8333V4.16667H16.6667L16.6676 15.8333H10.8334Z" fill="black"/>
<path d="M12.5 5.8335H15V7.50016H12.5V5.8335ZM12.5 9.16683H15V10.8335H12.5V9.16683Z" fill="black"/>
</svg>
  )
}
export default ContentActiveSVG;