import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { deleteBatch } from '../API/Batches';
import { SUCCESS_TOAST } from './toasters';
import { deleteKid } from '../API/kids-mgmt';
export default function AlertDialog(props) {

  const handleClose = () => {
    props.setOpen(false);

    
  };

  // if ˳request type is User then delete User else delete Batch
  const deleteListedRequest = () => {

    if(props.type === "User"){
      deleteKid(props._id).then((res) => {
        // console.log(res);
        props.setDeleted(true);
        SUCCESS_TOAST("User Deleted Successfully");
        
      }
      )
      .catch((err) => {
        console.log(err);
        props.setDeleted(false);
      }
      )


    }
    else{
      deleteBatch(props._id).then((res) => {
        console.log(res);
        props.setDeleted(true);
        SUCCESS_TOAST("Batch Deleted Successfully");
        
      }
      )
      .catch((err) => {
        console.log(err);
        props.setDeleted(false);
      }
      )
  
    }


    
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete {props.type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {props.type}? You cannot undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={(e)=>{
            deleteListedRequest();
            handleClose();
          }}  >
            Yes
          </Button>
          <Button onClick={(e)=>{
            handleClose();
          }}>No</Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}