import React from 'react'

export default function DashboardActive() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_1_144" fill="white">
<rect x="8.44336" width="6.56706" height="6.5625" rx="1"/>
</mask>
<rect x="8.44336" width="6.56706" height="6.5625" rx="1" stroke="#22242C" strokeWidth="3" mask="url(#path-1-inside-1_1_144)"/>
<mask id="path-2-inside-2_1_144" fill="white">
<rect x="8.44336" y="7.5" width="6.56706" height="6.5625" rx="1"/>
</mask>
<rect x="8.44336" y="7.5" width="6.56706" height="6.5625" rx="1" stroke="#22242C" strokeWidth="3" mask="url(#path-2-inside-2_1_144)"/>
<mask id="path-3-inside-3_1_144" fill="white">
<rect y="7.5" width="6.56706" height="6.5625" rx="1"/>
</mask>
<rect y="7.5" width="6.56706" height="6.5625" rx="1" stroke="#22242C" strokeWidth="3" mask="url(#path-3-inside-3_1_144)"/>
<mask id="path-4-inside-4_1_144" fill="white">
<rect width="6.56706" height="6.5625" rx="1"/>
</mask>
<rect width="6.56706" height="6.5625" rx="1" stroke="#22242C" strokeWidth="3" mask="url(#path-4-inside-4_1_144)"/>
</svg>

  )
}
