import { call } from "../helpers/axiosInterceptor";

async function AdminLogin(userData: any) {
  const { data } = await call.post("admin/login", userData,
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  localStorage.setItem("refToken",data.refToken)
  return data;
}

export default AdminLogin;
