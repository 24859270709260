import * as React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { getAllPoints } from "../../API/points";
import { Avatar, Chip, ChipProps } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddnewCard from "../../components/addNewCard/addNewCard";
import { ERROR_TOAST } from "../../helpers/toasters";
import MainTable from "../../components/Datagrid/dataGridTable";

type data = {
  name: string;
  description: string;
  logo: string;
  value: Number;
  __v: Number;
  _id: string;
};

const initialUpdatingRow = {
  description: "",
  name: "",
  logo: "",
  value: 0,
  __v: 0,
  _id: "",
};

export default function PointsList() {
  const [rows, setRows] = React.useState<String[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [updateCourse, setUpdateCourse] = React.useState(false);
  const [updatingRow, setUpdatingRow] =
    React.useState<data>(initialUpdatingRow);

  React.useEffect(() => {
    getFirstData();
    setLoading(true);
  }, []);

  const getFirstData = () => {
    getAllPoints()
      .then((data) => {
        if (!data) {
          setRows([]);
          setLoading(false);
          return ERROR_TOAST("error Connecting to Server !");
        }
        setRows(data);
        setLoading(false);
      })
      .catch((e) => {
        return ERROR_TOAST("something went wrong !");
      });
  };

  const columns = [
    {
      field: "_id",
      hide: true,
    },
    {
      field: "logo",
      headerName: "Card Logo",
      renderCell: (params: any) => {
        return (
          <>
            <Avatar src={params.value} />
          </>
        );
      },
      width: 200,
    },
    {
      field: "name",
      headerName: "Card Name",
      width: 200,
      editable: false,
    },
    {
      field: "description",
      headerName: "Card Description",
      width: 450,
      editable: false,
    },
    {
      field: "value",
      headerName: "Points",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
      width: 140,
      editable: false,
    },
    {
      field: "",
      width: 60,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
        );
      },
    },
  ];

  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    console.log(row);
    setUpdateCourse(true);
    setUpdatingRow(row);
  };

  function getChipProps(params: GridRenderCellParams): ChipProps {
    return {
      label: params.value,
      style: {
        background: "#FA8F1C",
        width: "63px",
        height: "30px",
        color: "#fff",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "16px",
        borderColor: "#FA8F1C",
      },
    };
  }
  const handelClose = () => {
    setUpdateCourse(false);
    getFirstData();
  };

  return (
    <>
      <MainTable rows={rows} columns={columns} loading={loading} />
      {updateCourse && (
        <AddnewCard handelClose={handelClose} data={updatingRow} />
      )}
    </>
  );
}
