import React, { useEffect, useState } from "react";
// import { LoginData } from "../../interface/loginInterface";
import "./login.css";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { User } from "../../types/login";
import mainLogo from "../../icons/logo.svg";
import AdminLogin from "../../API/adminLogin";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/actions";

import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";

//css for loader

const override = css`
  display: block;
  margin: 0 auto;
`;

//hot toast for showing error in login

const Login = (props: any) => {
  const [user, setUserData] = useState<User>({ email: "", password: "" });

  const [loading, setLoading] = useState<boolean>(false);

  const [isCancelled, setCancelled] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<boolean>(false);


  const handelChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setUserData({ ...user, [name]: value });
    setUserInput(true);
  };
  const dispatch = useDispatch();

  const showData: any = async () => {
    setLoading(true);
    try {
      const data = await AdminLogin(user);
      console.log(data);

      if (data.message === "success") {
        if(!isCancelled){
          dispatch(setUser(data.data));
          localStorage.setItem("refToken", data.data.accessToken);
          setLoading(false);
          return SUCCESS_TOAST(`Welcome ${data.data.name} 🎉`);
        }
        
      }
      if (data === "No admin is registered with this email address") {
        setLoading(false);
        return INFO_TOAST("No admin is registered with this email address");
      }
      if (data.message[0].property === "password") {
        setLoading(false);
        return INFO_TOAST("Please Enter a Valid Password");
      } else {
        setLoading(false);
        return INFO_TOAST("Something Went Wrong, try again !");
      }
    } catch (e) {
      if (!isCancelled) {
        setLoading(false);
        return ERROR_TOAST("Email Or Password are not correct !");
      }
      
    }
  };
//memory leak warnings fixed here
useEffect(()=>{
  if(userInput){
    showData();
  }
  
  return () => {
    setCancelled(false);
    setUserInput(false)
  };
},[])


  return (
    <>
      <div className="background-login">
        <div className="card-login">
          <div className="top-login">
            <h2 className="login-text">Login</h2>
            <img src={mainLogo} alt="suprkid logo" />
          </div>
          <div id="login-middle-border"></div>
          <div className="center-login">
            <label className="login-label" htmlFor="email">
              Email ID
            </label>
            <input
              type="email"
              name="email"
              className="login-inp"
              onChange={handelChange}
            />
            <label className="login-label" htmlFor="pass">
              Password
            </label>
            <input
              type="password"
              name="password"
              className="login-inp"
              onChange={handelChange}
            />
            <a className="login-forgot" href="/">
              Forgot Password?
            </a>
          </div>
          <div className="bottom-login">
            <button
              className="login-btn"
              onClick={showData}
              disabled={user.email === "" && user.password === ""}
            >
              {loading ? <BeatLoader color={"#fff"} css={override} /> : "Login"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;