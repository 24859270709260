import axios from "axios";
import { call } from "../helpers/axiosInterceptor";

const getChallenges = async () => {
  try {
    // const { data } = await axios.get(`${process.env.REACT_APP_DEV_URL}challenge/read`,{headers: {
    //   Authorization: "Bearer " + localStorage.getItem("refToken"),
    // },});
    console.log(localStorage.getItem("refToken"))
    const { data } = await call.get(`challenge/read`,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},
    );
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const createChallenge = async (data: any) => {
  try {
    const res = await call.post("challenge/", { data },
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const updateChallenge = async (id:any,data: any) => {
  try {
    const res = await call.patch(`challenge/${id}`,  data ,
    {headers: {
      Authorization: "Bearer " + localStorage.getItem("refToken"),
    }},);
    return res;
  } catch (e) {
    
    return e;
  }
};
export { getChallenges, createChallenge, updateChallenge };
