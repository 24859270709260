import React from "react";

export default function CoachMgmtActiveSVG() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.23368 3.37221C9.23368 1.51264 7.72104 0 5.86147 0C4.0019 0 2.48926 1.51264 2.48926 3.37221C2.48926 5.23178 4.0019 6.74442 5.86147 6.74442C7.72104 6.74442 9.23368 5.23178 9.23368 3.37221ZM4.01833 3.37221C4.01833 2.35582 4.84508 1.52907 5.86147 1.52907C6.87786 1.52907 7.70461 2.35582 7.70461 3.37221C7.70461 4.3886 6.87786 5.21535 5.86147 5.21535C4.84508 5.21535 4.01833 4.3886 4.01833 3.37221Z"
        fill="black"
      />
      <path
        d="M5.86143 7.13565C2.62958 7.13565 0 9.76523 0 12.9971V14.0165C0 15 0.80037 15.8004 1.78391 15.8004H9.93894C10.9225 15.8004 11.7229 15 11.7229 14.0165V12.9971C11.7229 9.76523 9.09327 7.13565 5.86143 7.13565ZM10.1938 14.0165C10.1938 14.1568 10.0793 14.2713 9.93894 14.2713H1.78391C1.64355 14.2713 1.52907 14.1568 1.52907 14.0165V12.9971C1.52907 10.6084 3.47276 8.66472 5.86143 8.66472C8.2501 8.66472 10.1938 10.6084 10.1938 12.9971V14.0165Z"
        fill="black"
      />
      <path
        d="M13.6949 3.89932L11.801 5.79324L11.1056 5.09839C10.807 4.79974 10.3232 4.79974 10.0245 5.09839C9.72588 5.39703 9.72587 5.88134 10.0245 6.17948L11.2604 7.41488C11.4097 7.56421 11.6054 7.63887 11.801 7.63887C11.9966 7.63887 12.1922 7.56421 12.3415 7.41488L14.776 4.98042C15.0746 4.68177 15.0746 4.19796 14.776 3.89932C14.4773 3.60068 13.9935 3.60068 13.6949 3.89932Z"
        fill="black"
      />
    </svg>
  );
}
