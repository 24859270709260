import React, { useEffect } from "react";
import { tilesData } from "../../constants/content-mgmt-tiles";
import "./content-management.css";
import ManageEditorialField from "./manageEditorialField";
import ManageKnowledgeBox from "./manageKnowledgeBox";
import ManageLiveTiles from "./manageLiveTiles";

const ContentMgmt: React.FC = () => {
  const [showContents, setShowContents] = React.useState(1);

  useEffect(() => {
    console.log(showContents);
  }, [showContents]);

  return (
    <div className="contentheader">
      <div className="content-container">
        <h3 className="content-mgmt-header">Content Management</h3>

        <div className="manage-content">
          {tilesData.map((m: any) => (
            <div
              className={`contentBtnDiv ${
                showContents == m.id ? "contentBtnDivActive" : ""
              } 
              `}
              key={m.id}
              onClick={() => setShowContents(m.id)}
            >
              <p className={`contentBtn`}>{m.name}</p>
              <div className="content-border"></div>
            </div>
          ))}
        </div>
        {showContents === 1 && <ManageLiveTiles />}
        {showContents === 2 && <ManageEditorialField />}
        {showContents === 3 && <ManageKnowledgeBox />}
      </div>
    </div>
  );
};
export default ContentMgmt;
