import DashBoard from "../icons/Dashboard";
import CourseSVG from "../icons/coursesSetUp";
import BatchAllocationSVG from "../icons/batchAllocation";
import PointSetUpSVG from "../icons/pointSetUp";
import CoachMgmtSVG from "../icons/coachMgmt";
import KidsProfileSVG from "../icons/kidsProfile";
import StudentManagementSVG from "../icons/studentManagement";
import EventsSVG from "../icons/events";
import DashboardActive from "../icons/dashboardActive";
import CourseSetUpActive from "../icons/CourseSetUpActive";
import BatchesActive from "../icons/batchesActive";
import PointsActive from "../icons/pointsActive";
import ContentManagementSVG from "../icons/contentManagement";
import ContentActiveSVG from "../icons/contentActive";
import { RootStateOrAny, useSelector } from "react-redux";
import EventsActiveSVG from "../icons/eventActive";
import KidsProfileActiveSVG from "../icons/kidsProfileActiveSVG";
import StudentManagementActiveSVG from "../icons/studentManagementActive";
import CoachMgmtActiveSVG from "../icons/coachMgmtActive";

const MenuList = () => {
  const user = useSelector((state: RootStateOrAny) => state.auth);

  let list = [
    {
      id: 1,
      icon: <DashBoard />,
      activeIcon: <DashboardActive />,
      title: "Dashboard",
      link: "/",
    },
    {
      id: 2,
      icon: <CourseSVG />,
      activeIcon: <CourseSetUpActive />,
      title: "Course Set up",
      link: "/course-set-up",
    },
    {
      id: 3,
      icon: <BatchAllocationSVG />,
      activeIcon: <BatchesActive />,
      title: "Batch Allocation",
      link: "/batch",
    },
    {
      id: 4,
      icon: <PointSetUpSVG />,
      activeIcon: <PointsActive />,
      title: "Points Set up",
      link: "/points-set-up",
    },
    {
      id: 5,
      icon: <CoachMgmtSVG />,
      activeIcon: <CoachMgmtActiveSVG />,
      title: "Coach Management",
      link: "/coach-management",
    },
    {
      id: 6,
      icon: <KidsProfileSVG />,
      activeIcon: <KidsProfileActiveSVG />,
      title: "Kids Profile Management",
      link: "/kids-mgmt",
    },
    {
      id: 7,
      icon: <StudentManagementSVG />,
      activeIcon: <StudentManagementActiveSVG />,
      title: "Student Management",
      link: "/student-mgmt",
    },
    {
      id: 8,
      icon: <EventsSVG />,
      activeIcon: <EventsActiveSVG />,
      title: "Events and Challenge ",
      link: "/event-and-challenge",
    },
    {
      id: 9,
      icon: <ContentManagementSVG />,
      activeIcon: <ContentActiveSVG />,
      title: "Content Management",
      link: "/content-mgmt",
    },
  ];

  let Admin = [5, 6, 7, 9];

  if (user?.isCoach) {
    return [
      {
        id: 7,
        icon: <StudentManagementSVG />,
        activeIcon: <StudentManagementSVG />,
        title: "Student Management",
        link: "/student-mgmt",
      },
    ];
  }

  if (!user?.isSuper) {
    return [
      {
        id: 5,
        icon: <CoachMgmtSVG />,
        activeIcon: <CoachMgmtSVG />,
        title: "Coach Management",
        link: "/",
      },
      {
        id: 6,
        icon: <KidsProfileSVG />,
        activeIcon: <KidsProfileSVG />,
        title: "Kids Profile Management",
        link: "/kids-mgmt",
      },
      {
        id: 7,
        icon: <StudentManagementSVG />,
        activeIcon: <StudentManagementSVG />,
        title: "Student Management",
        link: "/student-mgmt",
      },
      {
        id: 9,
        icon: <ContentManagementSVG />,
        activeIcon: <ContentActiveSVG />,
        title: "Content Management",
        link: "/content-mgmt",
      },
    ];
  }

  return list;
};

export default MenuList;
