import React from "react";

export default function KidsProfileActiveSVG() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.26562 6.70797C2.06209 3.38938 4.98244 1 8.50014 1C12.0178 1 14.9382 3.45575 15.7347 6.70797"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8.43356C16 12.5486 12.6814 15.9336 8.5 15.9336C4.38496 15.9336 1 12.615 1 8.43356C1 7.83622 1.06637 7.23887 1.19912 6.7079C3.12389 6.7079 4.9823 5.91144 6.37611 4.58401L6.70797 4.25215L7.03982 4.58401C8.43363 5.97781 10.292 6.7079 12.2168 6.7079H15.7345C15.8673 7.23887 16 7.83622 16 8.43356Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3538 9.82749C11.8303 9.82749 12.2166 9.44119 12.2166 8.96466C12.2166 8.48813 11.8303 8.10183 11.3538 8.10183C10.8773 8.10183 10.491 8.48813 10.491 8.96466C10.491 9.44119 10.8773 9.82749 11.3538 9.82749Z"
        fill="black"
      />
      <path
        d="M5.51322 9.82748C5.98975 9.82748 6.37605 9.44118 6.37605 8.96465C6.37605 8.48812 5.98975 8.10182 5.51322 8.10182C5.03669 8.10182 4.65039 8.48812 4.65039 8.96465C4.65039 9.44118 5.03669 9.82748 5.51322 9.82748Z"
        fill="black"
      />
      <path
        d="M5.2478 11.6859C5.91152 12.6815 7.10621 13.3452 8.43364 13.3452C9.76108 13.3452 10.8894 12.6815 11.6195 11.6859"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
