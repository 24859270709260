import { call } from "../helpers/axiosInterceptor";

const getCoachesList = async () => {
  const data = await call.get("coach",
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);

  return data.data;
};

const createCoach = async (payload) => {
  const { data } = await call.post("coach", { ...payload },
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};

const getCoachbatch = async (id) => {
  const { data } = await call.get(`batch/coach-batch/${id}`,
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};

const getCoachbatches = async (id) => {
  const { data } = await call.get(`batch/search/coach-batch/${id}`,
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};

const updateCoach = async (payload) => {
  const { data } = await call.post("coach/update", { ...payload },
  {headers: {
    Authorization: "Bearer " + localStorage.getItem("refToken"),
  }},);
  return data;
};
export {
  createCoach,
  getCoachesList,
  getCoachbatch,
  updateCoach,
  getCoachbatches,
};
