import React,{useState} from "react";
import ManageAssisment from "../manageAssesment/manageAssesment";
import "./manageAssesment.css"
interface ManageButtonProp {
    value?: string;
  }
  
  export default function ManageAssesment(props: ManageButtonProp) {
    const [manageAss, setManageAss] = useState(false);
  
    const handelClose = () => {
      setManageAss(false);
    };
  
    return (
      <>
        <button className="manageBtnAssesment" onClick={() => setManageAss(!manageAss)}>
          <div className="btn-inside-Assesment">
            <span>Manage Assesments</span>
           
          </div>
        </button>
  
        {manageAss && <ManageAssisment handelClose={handelClose} />}
      </>
    );
  }