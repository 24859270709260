import React, { useEffect } from "react";
import "./dashboard.css";
import BasicTable from "./active-course";
import UserData from "../../components/userLoged/userData";
import AddButton from "../../components/buttons/addButton";
import { Link } from "react-router-dom";
import ContentMgmt from "./contentMgmt";
import BatchAllocation from "./BatchAllocation";
// import AddCourse from "../../components/addNewCourse/addCourse";
import { RootStateOrAny, useSelector } from "react-redux";
// import { store } from "../../store";

export default function DashBoard() {
  const user = useSelector((state: RootStateOrAny) => state.auth);

  // useEffect(() => {
  //   console.log(store.getState().auth.accessToken);
  // }, []);
  return (
    <>
      <div className="header">
        <button type="submit">search</button>
        <input
          className="dashboard-search"
          type="search"
          placeholder="Search course, student or Coach..."
        />
      </div>
      <div className="active-courses mt-5">
        <div className="options">
          <h1>Active Courses</h1>
          <div className="filters-and-buttons">
            <AddButton />
          </div>
        </div>
        <BasicTable />
      </div>
      <div className="down-tables-dashboard  d-flex justify-content-between mt-4">
        <ContentMgmt />
        <BatchAllocation />
      </div>
    </>
  );
}
