import React, { useEffect, useState } from "react";
import AddCoach from "../../components/buttons/addCoach";
import CoachList from "./coachList";

export default function CoachMgmt() {
  return (
    <>
      <div className="courseSetUp">
        <div className="topList">
          <h2 className="top-heading">Coach Management</h2>
          {/* <div className="search-inp">
            <button type="submit">search</button>
            <input
              className="dashboard-search"
              type="search"
              placeholder="Search Coach..."
            />
          </div> */}
          <AddCoach />
        </div>
        <CoachList />
      </div>
    </>
  );
}
