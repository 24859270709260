import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCourses } from "../../API/courses";
import { allocateCard, getAllPoints } from "../../API/points";
import { getAllStudentProfiles } from "../../API/student-mgmt";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import "./allocateCard.css";
type data = {
  name: string;
  course: string;
  cardtype: string;
  cardcategory: string;
  description: string;
  _id: string;
};

interface handelFuncProps {
  handelClose: () => void;
  data?: data;
}
export default function AllocateStudCard(props: handelFuncProps) {
  // const defaultFormContent = {
  //   course: "",
  //   user: "",
  //   certificate: null,
  //   reportCard: null,
  // };
  const [selectedStudentName,setSelectedStudentName]=useState("");
  const [studentName, setStudentName] = useState(props.data?.name || "");
  const [studCourse, setStudCourse] = useState(props.data?.course || "");
  const [cardType, setCardType] = useState(props.data?.cardtype || "");
  const [cardCategory, setCardCatedory] = useState(
    props.data?.cardcategory || ""
  );
  const [cardDesc, setCardDesc] = useState(props.data?.description || "");
  const [studentRows, setStudentRows] = useState([]);
  const [filteredArray, setfilteredArray] = useState([]);
  const [courseRows, setCourseRows] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [cardRows, setCardRows] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFirstData();
  }, []);

  const getFirstData = () => {
    getAllStudentProfiles()
      .then((data) => {
        if (!data) {
          toast.error("error Connecting to Server !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setStudentRows([]);
        }
        console.log(data);
        setStudentRows(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });

    getAllPoints()
      .then((data) => {
        if (!data) {
          toast.error("error Connecting to Server !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCardRows([]);
        }
        console.log(data);
        setCardRows(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });

    getCourses()
      .then((data) => {
        console.log(data);
        setCourseRows(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateData = async () => {
    const data = {
      name: studentName !== props.data?.name ? studentName : props.data.name,
      course:
        studCourse !== props.data?.course ? studCourse : props.data.course,
      description:
        cardDesc !== props.data?.description
          ? cardDesc
          : props.data.description,
      cardtype:
        cardType !== props.data?.cardtype ? cardType : props.data.cardtype,
      cardcategory:
        cardCategory !== props.data?.cardcategory
          ? cardCategory
          : props.data.cardcategory,
    };
    console.log(data);
  };

  const handleClick = () => {
    return props.handelClose();
  };

  const submitData = async () => {
    if (!studentName || studentName === "Select a student") {
      return ERROR_TOAST("Please Choose a Student !");
    }

    if (!studCourse || studCourse === "Choose a course") {
      return ERROR_TOAST("Please Choose a Course !");
    }

    if (!cardDesc || cardDesc === "") {
      return ERROR_TOAST("Please Provide a Description !");
    }

    if (!cardType || cardType === "select card type") {
      return ERROR_TOAST("Please select a type of Card !");
    }
    const data = {
      user: studentName,
      // course: studCourse,
      // cardDesc,
      files: ["aa"],
      card: cardType,
      // cardCategory,
    };
    const result = await allocateCard(data);
    console.log(result);
    if (!result) {
      return ERROR_TOAST("Error, Please try again !");
    } else {
      handleClick();
      return SUCCESS_TOAST("Card Allocated Successfully !");
    }
  };

  const handleChangeInName=(name)=>{
    setSelectedStudentName(name);
    const SelectedStudent=studentRows.filter((m:any)=>m.name.includes(name) || m.name.toLowerCase().includes(name) || m.name.toUpperCase().includes(name));
    setfilteredArray(SelectedStudent);


  }

  return (
    <>
      <div className="allocateCard">
        <div className="fullDescAllCard">
          <div className="headerofAC">
            <h2>Allocate Card</h2>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="acard-border-top"></div>
          <div className="ac-body">
            <div className="first-drop">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Student Name
                </label>
                <input
                name="Student_name"
                aria-label="Default select example"
                className="form-select setFirst"
                type="text"
                onChange={(e)=>handleChangeInName(e.target.value)}>
                  
                </input>
                <select
                  name="Student_name"
                  aria-label="Default select example"
                  className="form-select setFirst"
                  onChange={(e) => setStudentName(e.target.value)}
                >
                  <option>Select a student</option>;
                  {filteredArray.map((m: any) => (
                    <option key={m._id} value={m._id}>
                      {m.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="next-value">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Course
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="Allocate Card"
                    onChange={(e) => setStudCourse(e.target.value)}
                  >
                    <option>Choose a course</option>
                    
                        {courseRows.map((m: any) => (
                          <option key={m._id} value={m._id}>
                            {m.name}
                          </option>
                        ))}

                  </select>
                </div>
              </div>
              {/* <div className="next-value"> */}
              {/* <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Card Catagory
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="Allocate Card"
                    onChange={(e) => setCardCatedory(e.target.value)}
                  >
                    <option>select a card</option>
                    {cardRows.map((m: any) => (
                      <>
                        <option key={m._id} value={m._id}>
                          {m.name}
                        </option>
                      </>
                    ))}
                  </select>
                </div> */}
              {/* </div> */}
            </div>
            <div className="next-step-cardtype">
              <div className="dropdown" style={{ marginLeft: 70 }}>
                <label className="cat-hello" htmlFor="cat">
                  Card Type
                </label>
                <select
                  className="form-select setFirst"
                  aria-label="Default select example"
                  name="Allocate Card"
                  onChange={(e) => setCardType(e.target.value)}
                >
                  <option>select card type</option>
                  {cardRows.map((m: any) => (
                    <>
                      <option key={m._id} value={m._id}>
                        {m.name}
                      </option>
                    </>
                  ))}
                </select>
              </div>

              <div className="next-step-value">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cname">
                    Card Description
                  </label>
                  <input
                    type="text"
                    name="course_name"
                    value={cardDesc}
                    className="setCardDesc form-control dropdown-toggle"
                    onChange={(e) => setCardDesc(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="drag-drop-top-lineAc"></div>
          <div className="allocateCardbut d-flex">
            <button
              className={`allocatecard-button`}
              onClick={() => {
                props.data ? updateData() : submitData();
              }}
            >
              {props.data ? "Update Card" : "Allocate Card"}
            </button>

            <button className="cardcancel-button" onClick={() => handleClick()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
