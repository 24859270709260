import React, { useEffect, useState } from "react";
import AddBatch from "../../components/buttons/AddBatch";
import BatchList from "./batchList";

export default function Batches() {
  const [search, setSearch] = useState("");
  return (
    <>
      <div className="courseSetUp">
        <div className="topList">
          <h2 className="top-heading">Active Batches</h2>
          <div className="search-inp">
            <button type="submit">search</button>
            <input
              className="dashboard-search"
              type="search"
              value={search}
              placeholder="Search Batch..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <AddBatch />
        </div>
        <BatchList search={search} />
      </div>
    </>
  );
}
