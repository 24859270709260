import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Chip, ChipProps } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { blueGrey } from "@mui/material/colors";
// import AddPost from "./addPost";
import MainTable from "../../components/Datagrid/dataGridTable";

type data = {
  Category: string;
  AgeGroup: string;
  ContentUploaded: string;
  ImageUploded: string;
  Status: string;
  Action: string;
};
const initialUpdatingRow = {
  Category: "",
  AgeGroup: "",
  ContentUploaded: "",
  ImageUploded: "",
  Status: "",
  Action: "",
};

export default function ManageEditorialField() {
  const [loading, setLoading] = React.useState<boolean>(false);

  const [updateContent, setUpdateContent] = React.useState(false);

  const [updatingRow, setUpdatingRow] =
    React.useState<data>(initialUpdatingRow);

  // React.useEffect(() => {
  //     getFirstData();
  //     setLoading(true);
  //     }, []);

  // const getFirstData = () => {
  //   getAllStudentProfiles()
  //     .then((data ) => {
  //       if (!data) {
  //         toast.error("error Connecting to Server !", {
  //           position: "top-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           });
  //         setRows([]);
  //       }
  //       console.log(data);
  //       setRows(data);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const tempArr = [
    {
      id: 1,
      category: "Event",
      ageGroup: "4yrs-5yrs",
      thumbnailuploaded: true,
      imageUploded: true,
      contentUploded: false,
      status: "active",
      action: true,
    },
    {
      id: 2,
      category: "Event",
      ageGroup: "5yrs-6yrs",
      thumbnailuploaded: false,
      imageUploded: true,
      contentUploded: true,
      status: "active",
      action: true,
    },
    {
      id: 3,
      category: "Helth",
      ageGroup: "4yrs-5yrs",
      thumbnailuploaded: true,
      imageUploded: true,
      contentUploded: true,
      status: "active",
      action: false,
    },
    {
      id: 4,
      category: "Music",
      ageGroup: "4yrs-6yrs",
      thumbnailuploaded: true,
      imageUploded: false,
      contentUploded: false,
      status: "active",
      action: true,
    },
    {
      id: 5,
      category: "Helth",
      ageGroup: "6yrs-8yrs",
      thumbnailuploaded: false,
      imageUploded: false,
      contentUploded: true,
      status: "active",
      action: true,
    },
  ];

  const columns = [
    {
      field: "category",
      headerName: "Category",
      width: 150,
      editable: false,
    },
    {
      field: "ageGroup",
      headerName: "Age Group",
      width: 150,
      editable: false,
    },
    {
      field: "ThumbnailUploaded",
      headerName: "Thumbnail Uploaded",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
      width: 130,
      editable: false,
    },
    {
      field: "image/Video Uploded",
      headerName: "Image/Video Uploded",
      width: 130,
      editable: false,
    },
    {
      field: "content uploded",
      headerName: "CntentUploded",
      width: 130,
      editable: false,
    },
    {
      field: "action",
      headerName: "Status",
      description: "Status",
      type: "boolean",
      renderCell: (params: any) => {
        return (
          <Chip
            variant="outlined"
            size="small"
            {...getStatusChipProps(params)}
          />
        );
      },
      width: 150,
      editable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      editable: false,
    },
    {
      field: "",
      width: 40,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
        );
      },
    },
  ];

  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    setUpdateContent(true);
    setUpdatingRow(row);
  };
  function getStatusChipProps(params: GridRenderCellParams): ChipProps {
    if (params.value == false) {
      return {
        label: params.value == true ? "Active" : "Inactive",
        style: {
          background: blueGrey[700],
          width: 500,
          height: "30px",
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          borderColor: blueGrey[700],
        },
      };
    } else {
      return {
        label: params.value == true ? "Active" : "Inactive",
        style: {
          borderColor: "#5ED7C3",
          width: 500,
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          background: "#5ED7C3",
          // #5ED7C3
        },
      };
    }
  }

  function getChipProps(params: GridRenderCellParams): ChipProps {
    return {
      label: params.value,
      style: {
        background: "#FA8F1C",
        width: "63px",
        height: "30px",
        color: "#fff",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "16px",
        borderColor: "#FA8F1C",
      },
    };
  }

  return (
    <>
      <MainTable rows={tempArr} columns={columns} loading={loading} />
    </>
  );
}
