import * as  React from 'react';
import BatchSessions from './batchSessions';


function BatchAllocation() {
  
  return (
    <>
    <div className="header-BatchAllocation">
    <BatchSessions/>
    </div>
   </>
  )
}

export default BatchAllocation