import React from "react";
import { DataGrid, GridToolbar, GridRenderCellParams } from "@mui/x-data-grid";
import { getAllStudentProfiles } from "../../API/student-mgmt";
import { Chip, ChipProps } from "@mui/material";
import { toast } from "react-toastify";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { blueGrey, red } from "@mui/material/colors";
import AddTile from "./addTile";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

type data = {
  Category: string;
  ThumbnailUploaded: string;
  Status: string;
  Action: string;
};
const initialUpdatingRow = {
  Category: "",
  ThumbnailUploaded: "",
  Status: "",
  Action: "",
};

export default function ManageLiveTiles() {
  const [pageSize, setPageSize] = React.useState<number>(5);
  const [rows, setRows] = React.useState<String[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [updateContent, setUpdateContent] = React.useState(false);
  const [modalShow, setModalShow] = React.useState<boolean>(false);
  const [tileToEdit, settileToEdit] = React.useState(undefined);
  const [changeIsActive, setChangeIsActive] = React.useState<boolean>(false);
  const [updatingRow, setUpdatingRow] =
    React.useState<data>(initialUpdatingRow);

  // React.useEffect(() => {
  //     getFirstData();
  //     setLoading(true);
  //     }, []);

  // const getFirstData = () => {
  //   getAllStudentProfiles()
  //     .then((data ) => {
  //       if (!data) {
  //         toast.error("error Connecting to Server !", {
  //           position: "top-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           });
  //         setRows([]);
  //       }
  //       console.log(data);
  //       setRows(data);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  const tempArr = [
    {
      id: 0,
      category: "Special Offer",
      thumbnailUploaded: true,
      status: "active",
      action: true,
    },
    {
      id: 1,
      category: "Special Offer",
      thumbnailUploaded: true,
      status: "active",
      action: true,
    },
    {
      id: 2,
      category: "Special Offer",
      thumbnailUploaded: true,
      status: "active",
      action: true,
    },
    {
      id: 4,
      category: "Special Offer",
      thumbnailUploaded: true,
      status: "active",
      action: true,
    },
    {
      id: 5,
      category: "Special Offer",
      thumbnailUploaded: true,
      status: "active",
      action: true,
    },
  ];

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 30,
    height: 17,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,

      "&.Mui-checked": {
        transform: "translateX(12px)",

        color: "#fff",

        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#EA1857",
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: "#fff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 10,
      height: 10,
      borderRadius: 6,
      backgroundColor: "#8E8EA1",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.10)",
      boxSizing: "border-box",
    },
  }));

  const columns = [
    {
      field: "category",
      headerName: "Category",
      width: 250,
      editable: false,
    },

    {
      field: "thumbnailUploaded",
      headerName: "Thumbnail Uploaded",
      renderCell: (params: any) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
      width: 210,
      editable: false,
    },
    {
      field: "action",
      headerName: "Status",
      description: "Status",
      type: "boolean",
      renderCell: (params: any) => {
        return (
          <Chip
            variant="outlined"
            size="small"
            {...getStatusChipProps(params)}
          />
        );
      },
      width: 350,
      editable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      renderCell: () => {
        return <AntSwitch />;
      },
      width: 160,
      editable: false,
    },
    {
      field: "",
      width: 40,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
        );
      },
    },
  ];
  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    setUpdateContent(true);
    setUpdatingRow(row);
  };
  function getStatusChipProps(params: GridRenderCellParams): ChipProps {
    if (params.value == false) {
      return {
        // icon: <WarningIcon style={{ fill: red[500] }} />,
        label: params.value == true ? "Active" : "Inactive",
        style: {
          background: blueGrey[700],
          width: "63px",
          height: "30px",
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          borderColor: blueGrey[700],
        },
      };
    } else {
      return {
        label: params.value == true ? "Active" : "Inactive",
        style: {
          borderColor: "#5ED7C3",
          width: "63px",
          height: "30px",
          color: "#fff",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "16px",
          background: "#5ED7C3",
          // #5ED7C3
        },
      };
    }
  }
  function getChipProps(params: GridRenderCellParams): ChipProps {
    return {
      label: params.value ? "Yes" : "No",
      style: {
        background: "#7A9BF9",
        width: "63px",
        height: "30px",
        color: "#fff",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "16px",
        borderColor: "#7A9BF9",
      },
    };
  }
  //   const handleUpdate = (id: string, toChangeIsActive: boolean) => {
  //     setChangeIsActive(toChangeIsActive)
  //     setEventToEdit(events.find((event: any) => event._id == id))
  //     setModalShow(true)
  // }
  const handleClose = () => {
    setModalShow(false);
    // settileToEdit(undefined);
    // setChangeIsActive(false);
  };
  return (
    <div>
      <div className="tableHeader active-courses">
        <h1></h1>
        <>
          <button className="addTileBtn" onClick={() => setModalShow(true)}>
            <div className="btnContainerAT">
              <span>Add Tiles</span>
              <span>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.25 5.25H6.75V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790178 6.19891 0 6 0C5.80109 0 5.61032 0.0790178 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V5.25H0.75C0.551088 5.25 0.360322 5.32902 0.21967 5.46967C0.0790178 5.61032 0 5.80109 0 6C0 6.19891 0.0790178 6.38968 0.21967 6.53033C0.360322 6.67098 0.551088 6.75 0.75 6.75H5.25V11.25C5.25 11.4489 5.32902 11.6397 5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803C6.67098 11.6397 6.75 11.4489 6.75 11.25V6.75H11.25C11.4489 6.75 11.6397 6.67098 11.7803 6.53033C11.921 6.38968 12 6.19891 12 6C12 5.80109 11.921 5.61032 11.7803 5.46967C11.6397 5.32902 11.4489 5.25 11.25 5.25Z"
                    fill="#EB1556"
                  />
                </svg>
              </span>
            </div>
          </button>

          {modalShow && (
            <AddTile
              data={tileToEdit}
              handleClose={handleClose}
              changeIsActive={changeIsActive}
            />
          )}
        </>
      </div>

      <div style={{ height: 420, width: "100%", background: "white" }}>
        <DataGrid
          rows={tempArr}
          columns={columns}
          getRowId={() => Math.random()}
          pageSize={pageSize}
          rowHeight={54.5}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{ Toolbar: GridToolbar }}
          rowsPerPageOptions={[5, 10, 20]}
          loading={loading}
          pagination
          rowSpacingType="border"
          sx={{
            borderRadius: "15px",
            background: "#FFFFFF",
            boxShadow: "0px 0px 10px rgba(116, 128, 135, 0.07);",
            "& 	.MuiDataGrid-columnHeaders": {
              color: "#65708D",
              fontSize: "11px",
            },
            "& .MuiDataGridPro-row ": {
              color: "red",
            },
            "& .MuiDataGrid-row": {
              "&:hover, &.Mui-hovered": {
                background: "#F2FBFF",
              },
            },
          }}
        />
      </div>
    </div>
  );
}
