import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, GridRenderCellParams } from "@mui/x-data-grid";
import {
  createStudent,
  deleteKid,
  getAllStudents,
  getKidsBysearch,
} from "../../API/kids-mgmt";
import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import Chip, { ChipProps } from "@mui/material/Chip";
import AddNewKids from "../../components/addNewKids/addNewKids";
import { Add, Delete, DeleteOutlineRounded, DeleteRounded, EditRoadRounded } from "@mui/icons-material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MainTable from "../../components/Datagrid/dataGridTable";
import AlertDialog from "../../helpers/alertDialog";

// type data = {
//   kidsname: string;
//   gender: string;
//   age: string;
//   mobnumber: number;
//   city: string;
//   country: string;
//   courses: string;
//   _v: number;
//   _id: string;
// };
// const initialUpdatingRow = {
//   kidsname: "",
//   gender: "",
//   age: "",
//   mobnumber: 0,
//   city: "",
//   country: "",
//   courses: "",
//   _v: 0,
//   _id: "",
// };

export default function KidsList(props) {
  const initialUpdatingRow = {
    uniqueid: Number,
    kidsname: String,
    gender: String,
    age: Number,
    mobnumber: Number,
    city: String,
    country: String,
    courses: [],
    parents: String,
    _v: Number,
    _id: String,
  };
  const [pageSize, setPageSize] = useState(5);
  const [rows, setRows] = React.useState<String[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [updateKidsProfile, setUpdateKidsProfile] = React.useState(false);
  const [updatingRow, setUpdatingRow] = React.useState<any>(initialUpdatingRow);
  const [searchRows, setSearchRows] = React.useState<String[]>([]);
  const [userDeleted, setUserDeleted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [User_id, setUser_id] = React.useState<String>("");

  const fetchStudents =  () => {
    getAllStudents().then((res) => {
      if (res) {
        console.log(res);
        setRows(res);
        setLoading(false);
      }
     else {
      return ERROR_TOAST("Server error, Please refresh the page !");
    }
    });
  };
  const handleDeleteKidCell = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    console.log(row);
    setUser_id(row._id);
    setOpen(true);
    fetchStudents();
    // deleteKid(row._id).then((res) => {
    //   if (res) {
    //     SUCCESS_TOAST("Student Deleted Successfully");
    //     fetchStudents();
    //   } else {
    //     ERROR_TOAST("Server error, Please refresh the page !");
    //   }
    // });
  };

  React.useEffect(() => {
    if (props.search.length > 0) {
      getKidsBysearch(props.search)
        .then((data) => {
          if (data.message == "Not Found") {
            return INFO_TOAST(`Course Not Found With '${props.search}' Name!`);
          }
          setSearchRows(data.data);
        })
        .catch((e) => ERROR_TOAST("Something went wrong !"));
    }
    if (props.search.length == 0) {
      setSearchRows([]);
    }
  }, [props.search]);

  useEffect(() => {
    fetchStudents();
  }, [userDeleted]);
  const columns = [
    {
      field: "_id",
      hide: true,
    },
    {
      field: "name",
      headerName: "Kids Name",
      width: 200,
      editable: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 170,
      editable: false,
    },
    {
      field: "age",
      headerName: "Age",
      width: 120,
      editable: false,
    },
    {
      field: "couses",
      headerName: "courses",
      width: 150,
      editable: false,
      valueGetter:(params)=>{ 
        // return params.row.courses.length>0?params.row.courses[0].name:"" ;
        // console.log(params);
        return params.row.courses.map((course:any) => {
              return course.name;
          })
        }
        
      },
    {
      field: "contact",
      headerName: "Mobile Number",
      width: 180,
      editable: false,
    },
    // {
    //   field: "city",
    //   headerName: "City",
    //   width: 100,
    //   editable: false,
    // },
    {
      field: "city",
      headerName: "City",
      width: 150,
      editable: false,
    },
    {
      field: "parents_name",
      headerName: "Parents",
      // renderCell: (cellValues: any) => {
      //   console.log(cellValues);
      //   return <></>;
      // },
      width: 180,
      editable: false,
    },
    {
      field: "Edit",
      width: 60,
      renderCell: (cellValues: any) => {
        return (
          <EditRoundedIcon
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          />
        );
      },
    },
    {
      field: "Delete",
      width: 60,
      renderCell: (cellValues: any) => {
        return (
          <div>
            <DeleteOutlineRounded
            style={{ color: "#8E8EA1", cursor: "pointer" }}
            onClick={(event) => {
              handleDeleteKidCell(event, cellValues);
            }}
          />
          {open && (
            <AlertDialog 
            title="Delete User"
            description="Are you sure you want to delete this User?" open={open} setOpen={setOpen} _id={User_id} setDeleted={setUserDeleted} type={"User"}/>
          )}
          </div>
          
        );
      },
    },
  ];
 

   
  const handleCellClick = (
    e: React.MouseEvent,
    value: GridRenderCellParams
  ) => {
    const { row } = value;
    console.log(row);
    setUpdateKidsProfile(true);
    setUpdatingRow(row);
  };
  const handelClose = () => {
    setUpdateKidsProfile(false);
    // getFirstData();
  };
  return (
    <>
      <MainTable
        rows={searchRows.length > 0 ? searchRows : rows}
        columns={columns}
        loading={loading}
      />

      {updateKidsProfile && (
        <AddNewKids handelClose={handelClose} data={updatingRow} />
      )}
    </>
  );
}
