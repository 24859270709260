import React from "react";
// import './bootstrap.css'
import "./eventsAndChallenges.css";
import ChallengesManagement from "./ChallengesManagement";
import EventsManagement from "./EventsManagement";

const EventsAndChallenges: React.FC = () => {
  const [showEvents, setShowEvents] = React.useState(false);

  return (
    <div className="header2">
      <div className="container">
        <h3 className="event-heading">Events And Challenges Management</h3>
        <div className="eventOrChallenge">
          <div
            className={"eventChallangeBtnDiv"}
            onClick={() => setShowEvents(false)}
          >
            <p
              className={`eventChallangeBtn ${
                !showEvents ? "selectedBtn" : ""
              }`}
            >
              Manage Challenge
            </p>
          </div>
          <div
            className={"eventChallangeBtnDiv"}
            onClick={() => setShowEvents(true)}
          >
            <p
              className={`eventChallangeBtn ${showEvents ? "selectedBtn" : ""}`}
            >
              Manage Event
            </p>
          </div>
        </div>
        {showEvents ? <EventsManagement /> : <ChallengesManagement />}
      </div>
    </div>
  );
};

export default EventsAndChallenges;
