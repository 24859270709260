import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { createCoach, updateCoach } from "../../API/coach-mgmt";
import { getCourseFillDetails } from "../../API/courses";
import { ERROR_TOAST, INFO_TOAST, SUCCESS_TOAST } from "../../helpers/toasters";
import moment from "moment";
import "./addCoach.css";

interface handelFuncProps {
  handelClose: () => void;
  data?: {
    name: string;
    category: string[];
    trainingModel: boolean;
    dob: Date;
    contact: string;
    email: string;
    password: string;
    address: string;
    city: string;
    country: string;
    __v?: Number;
    _id: string;
  };
}

export default function AddNewCoach(props: handelFuncProps) {
  const [List, setList] = useState<any>([]);
  const [category, setCategory] = useState(props.data?.category || "");
  const [name, setName] = useState(props.data?.name || "");
  const [contact, setContact] = useState(props.data?.contact || "");
  const [city, setCity] = useState(props.data?.city || "");
  const [email, setEmail] = useState<any>(props.data?.email || "");
  const [address, setAddress] = useState(props.data?.address || "");
  const [pass, setPass] = useState<any>(props.data?.password || "");
  const [country, setCountry] = useState(props.data?.country || "");
  const [trainigMode, setTraningMode] = useState<boolean>(
    props.data?.trainingModel || false
  );
  const [dob, setDob] = useState(
    props.data?.dob
      ? moment(props.data?.dob).utc().format("YYYY-MM-DD")
      : "2017-05-24"
  );
  const handleClick = () => {
    return props.handelClose();
  };

  useEffect(() => {
    getCourseFillDetails()
      .then((data) => {
        setList(data);
        setCategory(data[0]._id);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const showData = async () => {
    if (!name || name.length < 3) {
      return INFO_TOAST("Please insert a valid name !");
    }

    if (!dob) {
      return INFO_TOAST("Date of Birth is required !");
    }

    if (!category) {
      return INFO_TOAST("choose a category !");
    }

    if (!contact || contact.length > 10 || contact.length < 10) {
      return INFO_TOAST("Please insert a valid mobile number !");
    }

    if (!address) {
      return INFO_TOAST("Address is required !");
    }

    if (!city) {
      return INFO_TOAST("please add coach's city !");
    }

    if (!country) {
      return INFO_TOAST("please add coach's country!");
    }

    if (!email) {
      return INFO_TOAST("please add an email !");
    }

    if (!pass) {
      return INFO_TOAST("please add a password!");
    }

    const data = {
      name,
      dob,
      category,
      contact,
      trainingModel: trainigMode ? "online" : "offline",
      address,
      city,
      isCoach: true,
      email,
      country,
      password: pass,
    };

    const result = await createCoach(data);

    if (!result) {
      return ERROR_TOAST("something wrong happend, please try again!");
    }

    if (result.status == "failed") {
      return ERROR_TOAST(result.message);
    }
    if (result.response?.message === "Coach created successfully") {
      SUCCESS_TOAST("Coach created successfully !");

      return setTimeout(() => {
        handleClick();
      }, 3000);
    }

    if (result?.message === "A coach already registered with this email") {
      return ERROR_TOAST("A coach already registered with this email !");
    } else {
      return ERROR_TOAST("something went wrong please try again !");
    }
  };

  const updateData = async () => {
    if (!name || name.length < 3) {
      return INFO_TOAST("Please insert a valid name !");
    }

    if (!dob) {
      return INFO_TOAST("Date of Birth is required !");
    }

    if (!category) {
      return INFO_TOAST("choose a category !");
    }

    if (!contact || contact.length > 10 || contact.length < 10) {
      return INFO_TOAST("Please insert a valid mobile number !");
    }

    if (!address) {
      return INFO_TOAST("Address is required !");
    }

    if (!city) {
      return INFO_TOAST("please add coach's city !");
    }

    if (!country) {
      return INFO_TOAST("please add coach's country!");
    }

    if (!email) {
      return INFO_TOAST("please add an email !");
    }

    // if (!pass) {
    //   return INFO_TOAST("please add a password!");
    // }
    const data = {
      _id: props.data?._id,
      name: name !== props.data?.name ? name : props.data.name,
      category:
        category !== props.data?.category ? category : props.data.category,
      trainingModel:
        trainigMode !== props.data?.trainingModel
          ? trainigMode
          : props.data.trainingModel,
      date: dob || props.data?.dob,
      contact: contact !== props.data?.contact ? contact : props.data.contact,
      address: address !== props.data?.address ? address : props.data.address,
      city: city !== props.data?.city ? city : props.data.city,
      country: country !== props.data?.country ? country : props.data?.country,
      email: email !== props.data?.email ? pass : props.data?.email,
      // password: pass !== props.data?.password ? pass : props.data?.password,
    };
    const result = await updateCoach(data);
    if (!result) {
      return ERROR_TOAST("something wrong happend, please try again!");
    }

    if (result.status == "failed") {
      return ERROR_TOAST(result.message);
    }

    SUCCESS_TOAST("Mentor/coach have been updated successfully !");
    handleClick();
  };

  return (
    <>
      <div className="addCoach">
        <div className="fullCoachDesc">
          <div className="headerACoach">
            <h2>{props.data ? "Update Coach" : "Add New Coach"}</h2>
            <div
              className="top-svg"
              onClick={() => handleClick()}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0ZM15 13.1807L11.5157 9.69643C11.2745 9.45518 10.9473 9.31964 10.6061 9.31964C10.2649 9.31964 9.93768 9.45518 9.69643 9.69643C9.45518 9.93768 9.31964 10.2649 9.31964 10.6061C9.31964 10.9473 9.45518 11.2745 9.69643 11.5157L13.1807 15L9.69643 18.4843C9.57697 18.6037 9.48221 18.7456 9.41757 18.9016C9.35292 19.0577 9.31964 19.225 9.31964 19.3939C9.31964 19.5629 9.35292 19.7301 9.41757 19.8862C9.48221 20.0423 9.57697 20.1841 9.69643 20.3036C9.81588 20.423 9.9577 20.5178 10.1138 20.5824C10.2699 20.6471 10.4371 20.6804 10.6061 20.6804C10.775 20.6804 10.9423 20.6471 11.0984 20.5824C11.2544 20.5178 11.3963 20.423 11.5157 20.3036L15 16.8193L18.4843 20.3036C18.6037 20.423 18.7456 20.5178 18.9016 20.5824C19.0577 20.6471 19.225 20.6804 19.3939 20.6804C19.5629 20.6804 19.7301 20.6471 19.8862 20.5824C20.0423 20.5178 20.1841 20.423 20.3036 20.3036C20.423 20.1841 20.5178 20.0423 20.5824 19.8862C20.6471 19.7301 20.6804 19.5629 20.6804 19.3939C20.6804 19.225 20.6471 19.0577 20.5824 18.9016C20.5178 18.7456 20.423 18.6037 20.3036 18.4843L16.8193 15L20.3036 11.5157C20.423 11.3963 20.5178 11.2544 20.5824 11.0984C20.6471 10.9423 20.6804 10.775 20.6804 10.6061C20.6804 10.4371 20.6471 10.2699 20.5824 10.1138C20.5178 9.9577 20.423 9.81588 20.3036 9.69643C20.1841 9.57697 20.0423 9.48221 19.8862 9.41757C19.7301 9.35292 19.5629 9.31964 19.3939 9.31964C19.225 9.31964 19.0577 9.35292 18.9016 9.41757C18.7456 9.48221 18.6037 9.57697 18.4843 9.69643L15 13.1807Z"
                  fill="#8E8EA1"
                />
              </svg>
            </div>
          </div>
          <div className="acoach-border-top"></div>
          <div className="acoach-body">
            <div className="first-drop">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Mentor Name*
                </label>
                <input
                  name="Mentor_name"
                  aria-label="Default select example"
                  className="form-select setFirst"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="next-value-of-coach">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Course Category*
                  </label>
                  <select
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="Course Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {List.map((m, i) => (
                      <option key={i} value={m._id}>
                        {m.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="next-value-of-coach">
                <div className="mx-3">
                  <label className="cat-hello mx-1" htmlFor="cat">
                    Training Mode
                  </label>
                  <div className="radio-child ">
                    <label className="align-label px-2">
                      <input
                        type="radio"
                        name="choice3"
                        checked={trainigMode}
                        onClick={() => setTraningMode(true)}
                      />
                      <span className="mx-2"> Online</span>
                    </label>
                    <label className="align-label px-4">
                      <input
                        type="radio"
                        name="choice3"
                        checked={!trainigMode}
                        onClick={() => setTraningMode(false)}
                      />
                      <span className="mx-2"> offline</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="second-drop-line-coach">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  Date of Birth*
                </label>
                {/* <input
                  className="form-select setThird setBatchThird"
                  aria-label="Default select example"
                  name="Date"
                /> */}
                <TextField
                  id="date"
                  className="form-select setDOB"
                  type="date"
                  defaultValue={dob}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
              <div className="next-dropvalue-of-coach">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Mobile Number*
                  </label>
                  <input
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    type={"number"}
                    value={contact}
                    name="Mobile Number"
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
              </div>
              <div className="next-dropvalue-of-coach">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cname">
                    Address*
                  </label>
                  <input
                    type="text"
                    name="Address"
                    value={address}
                    className="setCardDesc form-control dropdown-toggle"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="third-drop-line-coach">
              <div className="dropdown">
                <label className="cat-hello" htmlFor="cat">
                  City*
                </label>
                <input
                  className="form-select setThird setBatchThird"
                  aria-label="Default select example"
                  name="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="next-value-of-coach">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Country*
                  </label>
                  <input
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
              <div className="next-value-of-coach">
                <div className="dropdown">
                  <label className="cat-hello" htmlFor="cat">
                    Email*
                  </label>
                  <input
                    className="form-select setThird setBatchThird"
                    aria-label="Default select example"
                    name="email"
                    type={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {!props.data && (
                <div className="next-value-of-coach">
                  <div className="dropdown">
                    <label className="cat-hello" htmlFor="cat">
                      Password*
                    </label>
                    <input
                      className="form-select setThird setBatchThird"
                      aria-label="Default select example"
                      name="password"
                      type={"password"}
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="drag-drop-top-lineMa"></div>
          <div className="down-buttons d-flex">
            <button
              className="create-button"
              disabled={
                name == "" ||
                category == "" ||
                address == "" ||
                dob == "" ||
                city == "" ||
                country == "" ||
                contact == ""
              }
              onClick={() => {
                props.data ? updateData() : showData();
              }}
            >
              {props.data ? "Update Mentor" : "Add Mentor"}
            </button>
            <button className="cancel-button" onClick={() => handleClick()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
