import axios, { AxiosResponse } from "axios";

const uri =
  process.env.NODE_ENV == "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_URL;


const instance = axios.create({
  baseURL: uri,
  timeout:10000
});

// const responseBody = (response: AxiosResponse) => response.data;

export const call = {
  get: async (url: string,headers:any) =>  await instance.get(url,headers),
  post: async (url: string, body: any,headers:any) => await instance.post(url, body,headers),
  put: async (url: string, body: any,headers:any) => await instance.put(url, body,headers),
  delete: async (url: string,headers:any) => await instance.delete(url,headers),
  patch: async (url: string, body: any,headers:any) => await instance.patch(url, body,headers),
};
