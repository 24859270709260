export const tilesData = [
    {
        id : 1,
        name : "Manage Live Tiles",
    },
    {
        id : 2,
        name : "Manage Editorial Field",
    },
    {
        id : 3,
        name : " Manage Knowledge Box",
    }
];