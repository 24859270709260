import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddClasses from "../../components/buttons/addClasses";
import "./Classes.css";

const columns = [];

function Classes() {
  const [rows, setRows] = React.useState([]);
  return (
    <>
      <div className="allocateTableSize">
        <TableContainer component={Paper} className="p-3">
          <div className="d-flex justify-content-between">
            <h2 className="m-2 header-downTables-dashboard">Classes</h2>
            <AddClasses />
          </div>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="table-head-dashboard">
                <TableCell align="left">Class Title</TableCell>
                <TableCell align="left">Duration</TableCell>
                <TableCell align="left">Short Description</TableCell>
                <TableCell align="left">Thumbnail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((m: any) => (
                <TableRow
                  hover={true}
                  key={m._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {m.course_id}
                  </TableCell>
                  <TableCell align="left">{m.classtitle}</TableCell>
                  <TableCell align="left">{m.duration}</TableCell>
                  <TableCell align="left">{m.shortdescription}</TableCell>
                  <TableCell align="left">{m.Thumbnail}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Classes;
