import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import logo from "../../icons/logo.svg";
import minimize from "../../icons/minimize.svg";
import "./sideBar.css";
import MenuList from "../../constants/sideBar.constants";

const SidebarItem = (props: any) => {
  return (
    <div className="sidebar__item">
      <div
        className={`sidebar__item-inner ${
          props.active === props.path ? "active" : ""
        }`}
      >
        <span
          className={`span-icon `}
          style={
            props.active === props.path
              ? { paddingLeft: "6px" }
              : { paddingLeft: "10px" }
          }
        >
          {props.active !== props.path ? props.icon : props.activeIcon}
        </span>
        <span
          className={`span-text ${props.show ? "" : "activate-sidebar"} `}
          style={
            props.active === props.path
              ? { color: "#22242C" }
              : { paddingLeft: "3px" }
          }
        >
          {props.title}
        </span>
      </div>
    </div>
  );
};

const Sidebar = (props: any) => {
  const menuItems = MenuList();
  const [show, setShow] = useState(false);
  const [activeLink, setActiveLink] = useState(window.location.pathname);

  return (
    <div
      className={`sidebar ${show ? "sideBar-minimize" : "sideBar-maximize"}`}
    >
      <div
        className={`sideBar-minimize-btn ${show ? "sideBar-btn-active" : ""}`}
      >
        <img
          className={`minimize-icon ${show ? "rotate" : " "}`}
          src={minimize}
          alt="less than arrow"
          onClick={() => {
            setShow(!show);
            props.isActive();
          }}
        />
      </div>
      {!show && (
        <div className={`sidebar__logo ${show ? "" : "activate-sidebar"}`}>
          <img style={{ height: "60px" }} src={logo} alt="suprkid logo" />
        </div>
      )}
      {menuItems.map((m) => (
        <Link to={m.link} key={m.id} onClick={() => setActiveLink(m.link)}>
          <SidebarItem
            title={!show ? m.title : null}
            icon={m.icon}
            path={m.link}
            activeIcon={m.activeIcon}
            active={activeLink}
            show={show}
          />
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
