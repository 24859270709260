import React from 'react'

export default function PointSetUpSVG() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.0242 9.29839C4.18449 9.45449 4.25161 9.68297 4.20124 9.90097L3.02083 15.0088L3.02012 15.0119C3.01319 15.0412 3.01526 15.0719 3.02608 15.1C3.0369 15.1281 3.05598 15.1524 3.08091 15.1696C3.10584 15.1869 3.13544 15.1962 3.16584 15.1964C3.19624 15.1966 3.22595 15.1876 3.25108 15.1706L3.25372 15.1689L3.25373 15.1689L7.56735 12.2951C7.78225 12.152 8.06213 12.152 8.27702 12.2951L12.5896 15.1682C12.5897 15.1683 12.5899 15.1683 12.59 15.1684C12.6159 15.1856 12.6466 15.1944 12.6778 15.1937C12.7091 15.193 12.7395 15.1828 12.7648 15.1644C12.79 15.146 12.8089 15.1203 12.8191 15.091C12.8292 15.0618 12.8302 15.0303 12.8219 15.0006C12.8218 15.0004 12.8218 15.0002 12.8217 15L11.3733 9.93522C11.306 9.69998 11.3788 9.44697 11.5607 9.28337L15.154 6.05191L4.0242 9.29839ZM4.0242 9.29839L0.685814 6.04702C0.685811 6.04702 0.685807 6.04701 0.685803 6.04701C0.664894 6.02664 0.650348 6.00074 0.64384 5.97242C0.637332 5.9441 0.639123 5.9145 0.649012 5.88715C0.658902 5.8598 0.676491 5.83581 0.699739 5.8181C0.722992 5.80038 0.75091 5.78969 0.78016 5.78737L0.780312 5.78736L5.29674 5.42874C5.52919 5.41028 5.7332 5.26686 5.82926 5.05438L7.78366 0.731087L7.78366 0.731088L7.78462 0.728948C7.79644 0.702548 7.81568 0.680061 7.84009 0.664251C7.8645 0.648439 7.89301 0.64 7.92219 0.64C7.95136 0.64 7.97987 0.648439 8.00428 0.664251C8.02869 0.68006 8.04794 0.702548 8.05975 0.728951L8.06072 0.731086L10.0151 5.05438C10.1112 5.26682 10.3151 5.41023 10.5475 5.42873L15.0639 5.78814L15.0641 5.78816M4.0242 9.29839L15.0641 5.78816M15.0641 5.78816C15.0939 5.79052 15.1222 5.80154 15.1457 5.81981C15.1692 5.83807 15.1867 5.86275 15.1962 5.89077C15.2057 5.91879 15.2068 5.94895 15.1993 5.97756C15.1919 6.00611 15.1762 6.03193 15.1542 6.05179L15.0641 5.78816Z" stroke="#8E8EA1" strokeWidth="1.28" strokeLinejoin="round"/>
</svg>

  )
}
